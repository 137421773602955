import { ISection } from '@gigin-work-space/model';
import { COLORS, DATA_STATUS, VARIANT_TYPE } from '@gigin-work-space/utils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Grid, IconButton, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { BKStyledButton, StyledDivider } from '../../utils';
import { FilePreview, MetaDetailsContainer } from '../common-ui';

import { enqueueSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { axiosInstance, endpoints } from '../../services';

export interface ViewableContainerHocProps {
  sectionList?: ISection[];
  mode?: string;
  relId: string;
  fields: any[];
  cancelConfirm?: () => void;
  navigateToStep: (param: string) => void;
}

// array of field data types to be excluded in view/confirm mode
const exculdedFieldDataType = ['file', 'employment_checkbox', 'address_checkbox'];

export const ViewableContainerHoc = (props: ViewableContainerHocProps) => {
  const { mode = 'view', relId, fields, sectionList, cancelConfirm, navigateToStep } = props;
  const [stepsLabel, setStepsLabel] = useState([]);
  const [currentActiveStep, setCurrentActiveStep] = useState(0);
  const [navParam] = useState(Object.values(useParams())[0]);
  const [fieldsValue, setFieldsValue] = useState<any[]>([]);
  const [visibilityState, setVisibilityState] = useState<{ [key: string]: boolean }>({});
  const [maskedValues, setMaskedValues] = useState<{ [key: string]: string }>({});

  // Use effect to set fields
  useEffect(() => {
    setFieldsValue(fields);
  }, [fields]);

  // function to check if media is present or not
  const isMediaEmpty = (fields: any[]) => {
    return fields?.some((data) => data?.field_data_type === 'file' && !!data?.field_value?.value);
  };

  // function to navigate to clicked section step if its completed
  const navigateToClickedStep = (data: ISection) => {
    navigateToStep(data?.meta?.goto?.destination);
  };

  // function to render field value
  const renderFieldValue = (data: any) => {
    switch (data?.validation?.rule) {
      case 'calender_date_month_year':
        return dayjs(data?.field_value?.value).format('MMM D, YYYY');
      case 'dob_date_month_year':
        return dayjs(data?.field_value?.value).format('MMM D, YYYY');
      case 'calender_year':
        return dayjs(data?.field_value?.value).format('YYYY');
      case 'calender_month_year': {
        if (data?.field_value?.value?.toLowerCase() === 'currently working') {
          return 'Currently Working';
        }
        return dayjs(data?.field_value?.value).format('MMMM, YYYY');
      }
      case 'aadhaar_number': {
        const formattedValue = data?.field_value?.value?.replace(/(.{4})/g, '$1 ').trim();
        return formattedValue;
      }
      default:
        return data?.field_value?.value
          ? data?.field_data_type === 'zip'
            ? data?.field_value?.file_name
            : data?.field_value?.value
          : '-';
    }
  };

  //   SIDE EFFECT
  useEffect(() => {
    const handleActiveStep = () => {
      if (sectionList) {
        const activeIndex = sectionList?.findIndex((data) => data?.rel_id === relId);
        if (activeIndex !== -1) {
          setCurrentActiveStep(activeIndex);
        } else {
          setCurrentActiveStep(0);
        }
      }
    };
    handleActiveStep();
  }, []);

  // function to handle API call and set field value
  const handleShowValue = async (data: any) => {
    try {
      const dataCollectionId = navParam?.split('/').slice(2).join('/');

      // Toggle visibility state
      setVisibilityState((prevState) => ({
        ...prevState,
        [data.field_id]: !prevState[data.field_id],
      }));

      if (!visibilityState[data.field_id]) {
        // Perform an API call to get the actual value only if it's not visible
        const response = await axiosInstance.get(
          `${endpoints.GET_PARAMETER_DETAIL_VALUE_FOR_DATA_COLLECTION}/${dataCollectionId}/${data.field_name}`,
        );

        if (response?.data) {
          // Set the value from the API response
          const newFieldsValue = fieldsValue.map((field) => {
            if (field.field_id === data.field_id) {
              return {
                ...field,
                field_value: response.data.dataCollected,
              };
            }
            return field;
          });
          setFieldsValue(newFieldsValue);

          // Store the masked value
          setMaskedValues((prevState) => ({
            ...prevState,
            [data.field_id]: data.field_value.value,
          }));
        }
      } else {
        // If making the value invisible, use the stored masked value
        const newFieldsValue = fieldsValue.map((field) => {
          if (field.field_id === data.field_id) {
            return {
              ...field,
              field_value: { value: maskedValues[data.field_id] },
            };
          }
          return field;
        });
        setFieldsValue(newFieldsValue);
      }
    } catch (error) {
      enqueueSnackbar('Error fetching the field value', { variant: VARIANT_TYPE.ERROR });
    }
  };

  return (
    <>
      {/* Stepper Section */}
      {sectionList && sectionList?.length > 1 && mode !== 'confirm' && (
        <section className="px-6 pt-6 w-full">
          <Box sx={{ width: '100%', position: 'sticky', top: 3, zIndex: 999 }}>
            <div className="bk-stepper-scroll">
              <Stepper activeStep={currentActiveStep} alternativeLabel>
                {sectionList?.map((data, id) => (
                  <Step
                    key={data?.rel_id}
                    completed={
                      data?.status?.toLowerCase() === DATA_STATUS.COMPLETED ||
                      data?.status?.toLowerCase() === DATA_STATUS.NOT_REQUIRED
                    }
                    disabled={data?.status?.toLowerCase() === DATA_STATUS.PENDING}
                    onClick={() => navigateToClickedStep(data)}>
                    <StepLabel className="completed-step-icon">{data?.title?.en}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            <Box className="bk-stepper-side-blur" />
          </Box>
        </section>
      )}
      <section
        className={
          mode === 'confirm' ? '' : stepsLabel?.length > 1 ? 'bk-main-stepper-section' : 'bk-main-view-section'
        }>
        <Stack spacing={2}>
          {/* Edit Section */}
          {mode === 'confirm' && (
            <>
              <Stack spacing={1} padding={'0 1.5rem'}>
                <MetaDetailsContainer
                  iconName="info_yellow_icon.svg"
                  noteText="After submitting the details you will not be able to add/edit information"
                  fontStyle="bK-body1"
                  bgColor="bk_tag_orange_bg"
                  textColor="bk_text_secondary"
                />
                <BKStyledButton fullWidth variant="outlined" onClick={cancelConfirm}>
                  Edit details
                </BKStyledButton>
              </Stack>
              <StyledDivider />
            </>
          )}
          {/* Details Section */}
          <Stack spacing={1} padding={'0 1.5rem'}>
            {fieldsValue &&
              fieldsValue?.map(
                (data) =>
                  !exculdedFieldDataType.includes(data?.field_data_type) && (
                    <Grid container spacing={1} key={data?.field_id} sx={{ marginLeft: 0 }} alignItems={'center'}>
                      <Grid item xs={5}>
                        <Typography className="bK-body2 text-bk_text_secondary ">{data?.label?.en}</Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography className="bk-sub-heading2 text-bk_text_primary">
                          {data?.field_value?.value ? renderFieldValue(data) : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        {data?.should_encrypt_hash && (
                          <IconButton onClick={() => handleShowValue(data)}>
                            {visibilityState[data.field_id] ? (
                              <VisibilityOffIcon
                                sx={{
                                  color: COLORS.bk_tag_blue_light,
                                }}
                              />
                            ) : (
                              <VisibilityIcon
                                sx={{
                                  color: COLORS.bk_tag_blue_light,
                                }}
                              />
                            )}
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  ),
              )}
          </Stack>
          {/* IF Media is empty then don't show */}
          {isMediaEmpty(fields) && (
            <>
              <StyledDivider />
              {/* Media Section */}
              <Stack spacing={1} padding={'0 1.5rem'}>
                {/* Details Section */}
                <Typography className="bK-body2 text-bk_text_secondary pl-2">
                  {relId === 'basic_detail' ? 'Profile Pic' : 'Document(s)'}
                </Typography>
                <Grid container spacing={1} sx={{ marginLeft: 0 }} alignItems={'center'}>
                  {fields &&
                    fields?.map(
                      (data) =>
                        data?.field_data_type === 'file' && (
                          <Grid item xs={relId === 'basic_detail' ? 6 : data?.grid} key={data?.field_id}>
                            <FilePreview
                              params={data}
                              fileDetails={data?.field_value}
                              mode="view"
                              url={data?.field_value?.value}
                            />
                          </Grid>
                        ),
                    )}
                </Grid>
              </Stack>
            </>
          )}
        </Stack>
      </section>
    </>
  );
};
