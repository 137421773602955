import { ISection } from '@gigin-work-space/model';
import {
  CandidateDataStore,
  OrderServiceRequestIdStore,
  otpGeneralDetailStore,
  SelfDeclarationStore,
  setAddressCheckBoxUsage,
  setCheckOtpVerified,
  setCheckProgressBar,
  setSaveDataPayload,
  setSelectedAnyCheck,
} from '@gigin-work-space/store';
import { Box, Stack, Typography } from '@mui/material';
import jsPDF from 'jspdf';
import { useNavigate } from 'react-router-dom';

import React, { useCallback, useEffect, useState } from 'react';

import {
  ConsentType,
  EmployerAppAssetIconsFileNames,
  SelfDeclaration,
  SESSION_KEYS,
  STATUS,
} from '@gigin-work-space/utils';
import { enqueueSnackbar } from 'notistack';
import { useSnapshot } from 'valtio';
import { axiosInstance, endpoints } from '../../services';
import { StyledPaperCard } from '../../utils';
import { CommonLoader, DisplayImage, Footer, Header, SectionList } from '../common-ui';
import { getConsentContentWithoutDeclaration } from './consent-content';
export const Dashboard = () => {
  const navigate = useNavigate();
  const [dashboardLayout, setDashboardLayout] = useState<any>();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [selfDeclarationData, setSelfDeclarationData] = useState<any>();

  const dataCollectionId = sessionStorage.getItem(SESSION_KEYS.DATA_COLLECTION_ID);
  const { otpGeneralDetail } = useSnapshot(otpGeneralDetailStore);

  useEffect(() => {
    // If dataCollectionId is not available in sessionStorage, navigate to /something-went-wrong
    if (!dataCollectionId) {
      // Navigate to the /something-went-wrong route
      navigate('/something-went-wrong');
    }
  }, [dataCollectionId, navigate]);

  const originalDate = new Date();

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  const formattedDateString = originalDate.toLocaleString('en-US', options);
  let candidateName = '';
  if (sessionStorage.getItem(SESSION_KEYS.GENERAL_DETAILS)) {
    candidateName = JSON.parse(sessionStorage.getItem(SESSION_KEYS.GENERAL_DETAILS) as string).candidate_info.name;
  }
  // function to update the progres bar percentage if a check is completed
  const updateCheckProgressPercentage = (data: any) => {
    const totalChecks = data?.sectionList?.length;
    const completedChecks = data?.sectionList?.filter(
      (check: any) => check?.status.toLowerCase() === SelfDeclaration.COMPLETED,
    ).length;
    if (completedChecks === 0) {
      setCheckProgressBar(5);
      return;
    }
    setCheckProgressBar(Math.round((completedChecks / totalChecks) * 100));
  };

  const handleClickNaviagtion = () => {
    const signatureStatus = selfDeclarationData?.self_declaration?.signature_status;
    const signatureType = selfDeclarationData?.self_declaration?.signature_type;
    signatureStatus.toLowerCase() === SelfDeclaration.PENDING
      ? signatureType.toLowerCase() === SelfDeclaration.MANUAL
        ? navigate('/selfDeclarationManual')
        : navigate('/selfDeclarationEsign')
      : navigate('/selfDeclarationAfterCompletion');
  };

  // function to get dashboard structure
  const getDashboardLayout = useCallback(async () => {
    // Handle API success fail scenarios
    try {
      setIsPageLoading(true);
      const response = await axiosInstance.get(`${endpoints.GET_DASHBOARD_MENU}/${dataCollectionId}`);
      if (response.data.success) {
        updateCheckProgressPercentage(response.data.data.section);
        setDashboardLayout(response.data.data.section);
      }
    } catch (error) {
      enqueueSnackbar('Error in getting dashboard menu', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } finally {
      setIsPageLoading(false);
    }
  }, []);

  const getDeclarationPDF = async () => {
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    });

    const content = getConsentContentWithoutDeclaration(otpGeneralDetail, formattedDateString);

    const fontSize = 10;
    const boldFontSize = 14;
    const pageHeight = pdf.internal.pageSize.height;

    pdf.setFontSize(boldFontSize);
    pdf.setFont('helvetica', 'bold');
    pdf.text('Authorization Note', pdf.internal.pageSize.getWidth() / 2, 10, { align: 'center' });

    pdf.setFontSize(fontSize);
    pdf.setFont('helvetica', 'normal');

    pdf.setLineWidth(0.5);
    pdf.line(30, 18, 180, 18);

    pdf.setFontSize(fontSize);

    // Split the text into lines
    const lines = pdf.splitTextToSize(content, 180);
    let y = 18;

    for (let i = 0; i < lines.length; i++) {
      // Check if line would be out of page
      if (y > pageHeight - 15) {
        pdf.addPage();
        y = 10; // Restart height on new page
      }
      pdf.text(lines[i], 10, y);
      y += 6; // Increment Y coordinate for next line
    }

    const pdfBlob = pdf.output('blob');

    const mediaData = {
      field_id: '',
      isEncrypted: true,
      filePathContext: 'bgv-check-file-upload',
      isTenantIsolated: true,
      isPrivate: true,
      shouldCompress: false,
    };

    const formData = new FormData();

    const timestamp = Date.now();
    const filename = `document_otp_${timestamp}.pdf`;

    formData.append('field_id', '');
    formData.append('media', JSON.stringify(mediaData));
    formData.append('file', pdfBlob, filename);

    try {
      const response = await axiosInstance.post(`${endpoints.POST_FILE_S3}`, formData);

      const payloadBody = {
        selfDeclarationDetails: {
          signatureStatus: SelfDeclaration.COMPLETED,
          signedDocument: {
            value: response?.data?.data?.value,
          },
        },
      };

      await axiosInstance.patch(`${endpoints.SELF_DECLARATION_SIGN_UPDATE}/${dataCollectionId}`, payloadBody);
    } catch (error) {
      enqueueSnackbar('Error getting declaration', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
  };

  const getSelfDeclarationData = async () => {
    try {
      const response = await axiosInstance.get(`${endpoints.GET_SELF_DECLARATION_DETAILS}/${dataCollectionId}`);

      if (response.data.success) {
        SelfDeclarationStore.self_declaration = {
          self_declaration: response?.data?.data?.selfDeclarationDetails?.selfDeclaration,
          data_collection_id: response?.data?.data?._id,
          signature_type: response?.data?.data?.selfDeclarationDetails?.signatureType,
          signature_status: response?.data?.data?.selfDeclarationDetails?.signatureStatus,
          signed_image: response?.data?.data?.selfDeclarationDetails?.signedImage,
          signed_document: response?.data?.data?.selfDeclarationDetails?.signedDocument,
          consent_template: response?.data?.data?.selfDeclarationDetails?.consentTemplate,
        };

        CandidateDataStore.DCCandidateDataCollectionProgress =
          response?.data?.data?.UIProgress?.DCCandidateDataCollectionProgress;

        OrderServiceRequestIdStore.orderServiceRequestId = response?.data?.data?.orderServiceRequestId;

        setSelfDeclarationData({
          self_declaration: SelfDeclarationStore.self_declaration,
        });

        if (SelfDeclarationStore?.self_declaration?.self_declaration === false) {
          getDeclarationPDF();
        }
      }
    } catch (error) {
      enqueueSnackbar('Error fetching self declaration details', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  useEffect(() => {
    getSelfDeclarationData();
  }, []);

  // SIDE EFFECTS
  // effect to get dashboard structure
  useEffect(() => {
    setSaveDataPayload([]);
    setSelectedAnyCheck({});
    setCheckOtpVerified(false);
    setAddressCheckBoxUsage(false, {});
    getDashboardLayout();
  }, [getDashboardLayout]);

  if (isPageLoading) {
    return <CommonLoader type="dashboard" />;
  }
  return (
    <>
      {/* Header Section */}
      <Header />
      {/* Main Section */}
      <section className="bk-main-section flex flex-col gap-4 pt-5">
        <Stack spacing={1}>
          <Typography className="bk-sub-heading2">Hello {candidateName} </Typography>
          {dashboardLayout?.sectionList?.every(
            (data: any) =>
              data?.status.toLowerCase() === STATUS.COMPLETED.toLowerCase() ||
              data?.status.toLowerCase() === STATUS.NOT_REQUIRED.toLowerCase(),
          ) ? (
            <Stack spacing={1} justifyContent={'center'} alignItems={'center'} marginTop={'20px !important'}>
              <DisplayImage imageName={EmployerAppAssetIconsFileNames.SUCCESS_ALL_COMPLETED} imageType="image" />
              <Typography className="bk-headline3">You have completed the survey</Typography>
              <Typography className="bK-body2 text-bk_text_secondary">
                You can view the entered data in the sections below.
              </Typography>
            </Stack>
          ) : (
            <Typography className="bK-body3 text-bk_text_secondary">
              Please fill all the details to complete your onboarding process.{' '}
            </Typography>
          )}
        </Stack>
        <Stack spacing={2}>
          {dashboardLayout &&
            dashboardLayout.sectionList.map((data: ISection, id: React.Key | null | undefined) => (
              <SectionList key={id} data={data} />
            ))}

          {selfDeclarationData?.self_declaration?.self_declaration ? (
            <StyledPaperCard
              elevation={0}
              onClick={handleClickNaviagtion}
              status={selfDeclarationData?.self_declaration?.signature_status}>
              <div className="flex items-center gap-2">
                <div className="dashboard-icon-background">
                  <DisplayImage imageName="signature-icon.svg" imageType="icon" className="w-auto h-5 object-contain" />
                </div>
                {/* TODO: replace en with current selected language symbol */}
                <Stack>
                  <Typography className="bk-sub-heading1 text-bk_text_primary">Self Declaration</Typography>
                  <Typography className="bK-body3 text-bk_text_secondary break-words">
                    {selfDeclarationData?.self_declaration?.signature_type.toLowerCase() ===
                    ConsentType.E_SIGN.toLowerCase()
                      ? 'E-signature required'
                      : 'Manual signature required on document'}
                  </Typography>
                </Stack>
              </div>
              <DisplayImage
                imageName={
                  selfDeclarationData?.self_declaration?.signature_status?.toLowerCase() === SelfDeclaration.COMPLETED
                    ? 'circle_success_filled_icon.svg'
                    : 'arrow_right.svg'
                }
                imageType="icon"
                width="20px"
                height="20px"
              />
            </StyledPaperCard>
          ) : (
            ''
          )}
        </Stack>
        <Stack className="!pb-2" direction={'row'} alignItems={'center'} justifyContent={'center'} marginTop="0.5rem">
          <Typography className="bK-body3 text-bk_text_secondary mr-1">Powered by </Typography>
          <DisplayImage imageName="gigin_logo.svg" imageType="image" width="32px" height="15px" />
        </Stack>
      </section>
      {/* Footer section */}
      <Footer>
        <div className="flex items-center gap-2">
          <div className="self-start">
            <DisplayImage imageName="lock_icon.svg" imageType="icon" width="10px" height="14px" />
          </div>
          <Typography className="bK-body3 text-bk_text_secondary">
            We processes your personal data in accordance with our{' '}
            <Box
              component={'span'}
              className="text-bk_tag_blue cursor-pointer"
              onClick={() => {
                navigate('/privacy-policy');
              }}>
              Privacy policy
            </Box>
          </Typography>
        </div>
      </Footer>
    </>
  );
};

export default Dashboard;
