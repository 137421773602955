import { Geolocation } from '@gigin-work-space/model';

export enum ConsentType {
  E_SIGN = 'E_SIGN',
  MANUAL = 'MANUAL',
  OTP_VERIFICATION = 'OTP_VERIFICATION',
}

export enum BgvServiceTeam {
  OPS_TEAM = 'OPS Team',
  SALES_TEAM = 'Sales Team',
  SELF = 'SELF',
}

export enum ServiceSuit {
  BGV = 'BGV',
  ATS = 'ATS',
  HIRING = 'HIRING',
}
export enum ExportExcelFileName {
  CASE_DETAILS = 'caseDetails',
  CHECK_DETAILS = 'checkDetails',
  ALL_CHECK_DETAILS = 'allCheckDetails',
  CLIENT_CHECK_DETAILS = 'clientCheckDetails',
  ALL_CASE_DETAILS = 'allCaseDetails',
  CHECK_ALLOCATION = 'checkAllocation',
  ALL_OPEN_CHECKS = 'AllOpenChecks',
}

export enum ExportExcelCTA {
  CASE_DETAILS = 'Export Case Details',
  CHECK_DETAILS = 'Export Check Details',
  ALL_OPEN_CHECK = 'All Open Checks',
}

export enum VARIANT_TYPE {
  DEFAULT = 'default',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
}

export enum AUTO_HIDE_TIMEOUT {
  DEFAULT = 2000,
  AVERAGE = 3000,
  LONGER = 5000,
  LONGEST = 10000,
}

export enum NOVU_NOTIFICATION_STATUS_TEXT {
  INTERIM_REPORT = 'Interim Report',
  FINAL_REPORT = 'Final Report',
  STATUS_NOT_FOUND = 'Status Not Found',
}

export enum NOVU_NOTIFICATION_STATUS_ICON_TYPE {
  TEXT = 'text',
  IMG = 'img',
  LINK = 'link',
}
export enum UserType {
  USER = 'USER',
  CANDIDATE = 'CANDIDATE',
  ACCOUNT = 'ACCOUNT',
  HIRING_USER = 'HIRING_USER',
  TENANT = 'TENANT',
}
export enum UpdatedBy {
  OPS_TEAM = 'OPS Team',
}
export enum AccountVerificationSource {
  EMAIL_LINK = 'EMAIL LINK',
  EMAIL_OTP = 'EMAIL OTP',
  MOBILE_OTP = 'MOBILE OTP',
  NOT_VERIFIED = 'NOT_VERIFIED',
}
export enum TENANT_NAME { //Use for OPS Portal security Tenant name added
  GIGIN = 'gigin',
}

export enum CONSENT_POLICY_TYPE {
  CONSENT_POLICY = 'CONSENT_POLICY',
}

export enum ORDER_FLOW_STATUS {
  DATA_COLLECTION = 'Data collection',
  INVITE_SEND = 'Invite send',
  VERIFICATION = 'Verification',
  QUALITY_CHECK = 'Quality check',
  REPORT = 'Report',
}

export enum PROGRESS_STATUS {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  INPROGRESS = 'INPROGRESS',
  PENDING = 'PENDING',
  BLOCKED = 'BLOCKED',
  CANCELLATION_REQUESTED = 'CANCELLATION REQUESTED',
  CANCELLED = 'CANCELLED',
}

export enum FILTER {
  MAPPED = 'MAPPED',
  UNMAPPED = 'UNMAPPED',
  All = 'All',
  DESC = 'DESC',
  ASC = 'ASC',
}

export enum EVENT_Suspicious_DETECTION {
  USER = 'REGISTRATION',
  CHAT = 'CHATS',
  JOB_POSTING = 'JOBPOSTS',
}

export enum DATA_MAPPING_PROGRESS_STEP {
  PENDING = 'PENDING',
  INITIATED = 'INITIATED',
  MAPPED = 'MAPPED',
  INPROGRESS = 'INPROGRESS',
  COMPLETED = 'COMPLETED',
  COMPLETE = 'COMPLETE',
  SUBMITTED = 'SUBMITTED',
}

export const EMPLOYMENT_END_DATE_PREFIX = 'employment_end_date';

export enum CHECK_SERVICE_STATUS {
  CREATED = 'CREATED',
  INITIATED = 'INVITE_SEND',
  DATACOLLECTION = 'DATACOLLECTION',
  VERIFICATION = 'VERIFICATION',
  QUALITY_CHECK = 'QUALITY_CHECK',
  REPORT = 'REPORT',
  CANCELATION_REQUESTED = 'CANCELLATION REQUESTED',
  CANCELLED = 'CANCELLED',
}

export enum Suspicious_User_Context {
  MULTIPLE_ACCOUNTS = 'MULTIPLE_ACCOUNTS',
  JOB_DESCRIPTION = 'JOB_DESCRIPTION',
  JOB_COMPANY_NAME = 'JOB_COMPANY_NAME',
  JOB_EMPLOYER_NAME = 'JOB_EMPLOYER_NAME',
  CHAT_TEXT = 'CHAT_TEXT',
}

export enum Suspicious_User_Sub_Context {
  FROM_ONE_MOBILE = 'FROM_ONE_MOBILE',
  FROM_ONE_DEVICE = 'FROM_ONE_DEVICE',
  BAD_WORDS = 'BAD_WORDS',
  MOBILE_SHARED = 'MOBILE_SHARED',

  LINK_SHARED = 'LINK_SHARED',
  EMAIL_SHARED = 'EMAIL_SHARED',
  BANNED_WORDS = 'BANNED_WORDS',
  MOBILE_REQUESTED = 'MOBILE_REQUESTED',
  OVER_INITIATION = 'OVER_INITIATION',
  COPIED_MESSAGES = 'COPIED_MESSAGES',
  BLACKLISTED_COMPANY = 'BLACKLISTED_COMPANY',
  SOCIAL_MEDIA_LINKS = 'SOCIAL_MEDIA_LINKS',
}

export enum CHECK_STATUS {
  INPROGRESS = 'INPROGRESS',
  QCPENDING = 'QC_PENDING',
  CLEAR = 'CLEAR',
  DISCREPANT = 'DISCREPANT',
  INSUFFICIENT = 'INSUFFICIENT',
  NO_RESPONSE = 'NO_RESPONSE',
  UNABLE_TO_VERIFY = 'UNABLE_TO_VERIFY',
  BLOCKED = 'BLOCKED',
  CANCELATION_REQUESTED = 'CANCELLATION REQUESTED',
  CANCELLED = 'CANCELLED',
  NOT_REQUIRED = 'NOT_REQUIRED',
}

export enum OVERALL_STATUS {
  PENDING = 'PENDING',
  INPROGRESS = 'INPROGRESS',
  SUCCESS = 'SUCCESS', //Updated from GREEN to SUCCESS
  FAIL = 'FAIL', //Updated from RED to FAIL
  TIMEOUT = 'TIMEOUT',
  BLOCKED = 'BLOCKED',
  CANCELLATION_REQUESTED = 'CANCELLATION REQUESTED',
  CANCELLED = 'CANCELLED',
  INSUFFICIENT = 'INSUFFICIENT',
  NO_RESPONSE = 'NO_RESPONSE',
}
export enum REPORT_STATUS {
  INPROGRESS = 'INPROGRESS',
  QCPENDING = 'QC_PENDING',
  CLEAR = 'CLEAR',
  DISCREPANT = 'DISCREPANT',
  INSUFFICIENT = 'INSUFFICIENT',
  NO_RESPONSE = 'NO_RESPONSE',
  UNABLE_TO_VERIFY = 'UNABLE_TO_VERIFY',
  BLOCKED = 'BLOCKED',
  CANCELATION_REQUESTED = 'CANCELLATION REQUESTED',
  CANCELLED = 'CANCELLED',
}

export enum UserLocationType {
  SEARCH_HISTORY = 'search_history',
  PREFERRED_JOB_LOCATION = 'preferred_job_history',
  RELOCATION = 'ready_to_relocate',
  NOT_TO_RELOCATION = 'not_to_relocate',
  PHYSICAL = 'physical_location',
  CURRENT_LOCATION = 'current_location',
  PREFERRED_JOB_CITIES = 'preferred_job_cities',
  HOMETOWN = 'hometown',
  PREVIOUS_CURRENT_LOCATION = 'previous_current_location',
  PREVIOUS_PREFERRED_LOCATION = 'previous_preferred_location',
  YOUTHHUB_SOURCED = 'YOUTHHUB_SOURCED',
}

export enum TalentLocationTypeInsideSchema {
  ADDRESS_DETAILS = 'addressDetails',
  ADDRESS_HISTORY_LIST = 'addressHistoryList',
  ADDRESS_SEARCH_HISTORY = 'addressSearchHistory',
  ADDRESS_PREFERRED_LOCATION = 'preferredLocation',
  NOT_PREFERRED_LOCATIOn = 'notPreferredLocation',
}

export enum ENUM_JOB_PREFERRED_LANGUAGE {
  HINDI = 'Hindi',
  ENGLISH = 'English',
  MARATHI = 'Marathi',
  TAMIL = 'Tamil',
  TELUGU = 'Telugu',
  BENGALI = 'Bengali',
  KANNADA = 'Kannada',
  MALAYALAM = 'Malayalam',
}
export enum ENUM_INTERVIEW_TYPE {
  IN_PERSON_INTERVIEW = 'in-person-interview',
  VIRTUAL_INTERVIEW = 'virtual-interview',
}
export enum ENUM_OPEN_TO_LOCATION_ALL_OVER {
  YES = 'yes',
  NO = 'no',
}
export enum EnumSuspiciousActions {
  WARNING = 'ADD_WARN_TEXT',
  MASKING = 'MASK_CHAT',
  FALSE_DETECTION = 'FALSE_DETECTION',
}

export enum JOB_ACTIVITY_ACTION {
  INITIATED = 'INITIATED',
  PUBLISH = 'PUBLISH',
  REPUBLISH = 'REPUBLISH',
  UNSWITCH = 'UNSWITCH',
  UNPUBLISH = 'UNPUBLISH',
  AUTO_APPROVE = 'AUTO_APPROVE',
  UNEXPIRE = 'UNEXPIRE',
  UNGHOST = 'UNGHOST',
  GHOST = 'GHOST',
  EDITED = 'EDITED',
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
  PAUSE = 'PAUSE',
  EXPIRE = 'EXPIRE',
  SUBMIT_FOR_REVIEW = 'SUBMIT_FOR_REVIEW',
  CLOSE = 'CLOSE',
}

export enum JOB_POST_STATUS {
  PUBLISHED = 'PUBLISHED',
  EXPIRED = 'EXPIRED',
  DRAFT = 'DRAFT',
  CLOSED = 'CLOSED',
  PAUSED = 'PAUSED',
  REJECTED = 'REJECTED',
  UNDER_REVIEW = 'UNDER_REVIEW',
  GHOSTED = 'GHOSTED',
  DELETED_FROM_DRAFT = 'DELETED_FROM_DRAFT',
  UNPUBLISHED = 'UNPUBLISHED',
  DELETED_BY_TEAM = 'DELETED_BY_TEAM',
  DELETED = 'DELETED',
}
export enum notAllowedStatusForJobCard {
  CLOSED = JOB_POST_STATUS.CLOSED,
  DRAFT = JOB_POST_STATUS.DRAFT,
}

export enum BULK_UPLOAD_STATUS {
  SUCCESS = 'SUCCESS',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  INPROGRESS = 'INPROGRESS',
  DISCREPANT = 'DISCREPANT',
  INSUFFICIENT = 'INSUFFICIENT',
  NO_RESPONSE = 'NO_RESPONSE',
}
export enum ORDER_CANDIDATE_LIST_ANALYTICS_STATUS {
  PENDING = 'PENDING',
  BLOCKER = 'BLOCKER',
  UNSURE = 'UNSURE',
  SUCCESS = 'SUCCESS',
}

export enum SeekerAvlStatus {
  WILL_BE_AVAILABLE_SOON = 'Will Be Available Soon',
  NOT_AVAILABLE = 'Not Available',
  AVAILABLE = 'Available',
}

//TODO: I DONT THIS WILL BE NEEDED NEED REVIEW
export enum COLLECTION_GROUP_TYPE {
  basic_detail = 'Basic Profile',
  id_proof_detail = 'ID Proofs',
  address_detail = 'Address',
  education_detail = 'Education',
  employment_detail = 'Employment',
  bank_detail = 'Bank Statement',
  reference_check = 'Referral',
  social_media_check = 'Social Media Detail',
  'managerial-level_reference' = 'Managerial Level Reference',
  credit_check = 'Credit Check',
  business_verification = 'Business Verification',
  police_varification = 'Police Verification',
  crc_check = 'CRC Check',
  global_database_check = 'Global Database Check',
  drug_test_check = 'Drug Test',
  cv_check = 'CV / Resume Check',
  employment_type = 'employment_detail',
}

//TODO: I DONT THIS WILL BE NEEDED NEED REVIEW

export enum STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  RECEIVED = 'RECEIVED',
  CANCELED = 'CANCELED',
  Initiated = 'INITIATED',
  COMPLETED = 'COMPLETED',
  NOT_REQUIRED = 'NOT_REQUIRED',
}

export enum CHECK_ITEM_STATUS {
  INITIATED = 'INITIATED',
  INPROGRESS = 'INPROGRESS',
  STOPPED = 'STOPPED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
}

export enum ORDER_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  CANCELED = 'CANCELED',
  DRAFT = 'DRAFT',
}

export enum ORDER_PROGRESS_COLORS {
  GREY = 'grey',
  GREEN = 'green',
  RED = 'red',
  YELLOW = 'yellow',
}

export enum ACCOUNT_TYPE {
  COMPANY = 'COMPANY',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum ACCOUNT_ROLE {
  VENDOR = 'VENDOR',
  CUSTOMER = 'CUSTOMER',
}

export enum CORE_USER_ROLE {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  ACCOUNTS_FINANCE = 'ACCOUNTS_FINANCE',
  TEAM_LEAD = 'TEAM_LEAD',
  TEAM_MEMBER = 'TEAM_MEMBER',
  OPS_TEAM = 'OPS_TEAM',
  TEMPORARY_MEMBER = 'TEMPORARY_MEMBER',
}

export enum Type {
  ADD_SUBSCRIBER = 'ADD_SUBSCRIBER',
  TRIGGER = 'TRIGGER',
}

export enum EVENT_STATUS {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PROCESSED = 'PROCESSED',
  PENDING = 'PENDING',
  UNKNOWN = 'UNKNOWN',
  RE_TRY1 = 'RE_TRY1',
  RE_TRY2 = 'RE_TRY2',
}
export enum SELF_DECLARATION_STATUS {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
}

export enum USER_ROLE {
  ADMIN = 'ADMIN',
  RECRUITER = 'RECRUITER',
}

export enum COLLECTIONS {
  CANDIDATE = 'CANDIDATE',
  USER = 'USER',
  ACCOUNT = 'ACCOUNT',
}

export enum CheckoutInitiationStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  INPROGRESS = 'INPROGRESS',
  CONFIRMED = 'CONFIRMED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  DELETED = 'DELETED',
}

export enum GenderType {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}

export enum OLD_GENDER {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other',
}

export const genderMapping: { [key in OLD_GENDER]: GenderType } = {
  [OLD_GENDER.MALE]: GenderType.MALE,
  [OLD_GENDER.FEMALE]: GenderType.FEMALE,
  [OLD_GENDER.OTHER]: GenderType.OTHER,
};

export enum OrderActivityStatus {
  ORDER_INITIATED = 'ORDER_INITIATED',
  ORDER_CHECKOUT = 'ORDER_CHECKOUT',
  ORDER_CONFIRM = 'ORDER_CONFIRM',
  ORDER_DELETED = 'ORDER_DELETED',
}

export enum SNSMessageType {
  NEW_MESSAGE = 'NEW_MESSAGE',
  REMINDER_MESSAGE = 'REMINDER_MESSAGE',
  WELCOME_MESSAGE = 'WELCOME_MESSAGE',
  NO_MESSAGE = 'NO_MESSAGE',
  KYC_EVENT_MESSAGE = 'KYC_EVENT_MESSAGE',
}

//These are the notification templates name the template are defined in the notification service
export enum NOVUTemplateName {
  EMAIL_VERIFICATION_BY_LINK = 'hiring-email-verification-by-link',
  CANDIDATE_DATA_COLLECTION_STARTING_EMAIL = 'candidate001',
  CANDIDATE_DATA_COLLECTION_STARTING_EMAIL_REMINDER_TO_OPEN_LINK = 'candidate002',
  CANDIDATE_DATA_COLLECTION_STARTING_EMAIL_REMINDER_INCOMPLETE_DATA = 'candidate003',
  ACCOUNT_CREATED_LOGIN_CREDS_EMAIL = 'emp001',
  CHECKOUT_INITIATION_COMPLETE_EMAIL = 'emp002',
  ACCOUNT_ORDER_CREATED_BY_USER_SUCCESSFUL_EMAIL = 'emp003',
  USER_CREATED_LOGIN_CREDS_EMAIL = 'usersignup',
  USER_INVITED_EMAIL = 'userinvited',
  SUPER_USER_CREATED_NORMAL_EMAIL = 'usercreated',
  RESET_PASSWORD_LINK = 'resetpassword',
  CANDIDATE_DATA_COLLECTION_COMPLETED_EMAIL = 'emp007',
  INTERIM_REPORT_GENERATION_COMPLETED_EMAIL = 'emp011',
  FINAL_REPORT_GENERATION_COMPLETED_EMAIL = 'emp012',
  DATA_INSUFFICIENCY_EMAIL = 'data-insufficiency-email-emp013',
  SIGNUP_EMAIL_OTP_EMAIL = 'signup-email-otp-verification',
  SIGNUP_VIA_OPS_EMAIL = 'signup-via-ops-email_verification',
  SIGNUP_OTP_VERIFICATION = 'signup-otp-verification',
  BGV_ON_BOARDING_REQUEST_TO_ADMIN_EMAIL = 'bgv-on-boarding-request-to-admin',
  CANDIDATE_DATA_COLLECTION_CANCELLED_EMAIL = 'case-cancellation-notification-email',
  CANDIDATE_BGV_VERIFICATION_CANCELLED_EMAIL = 'bgv-verification-notification-email',
  VERIFY_EMAIL_EMPLOYER_OTP = 'verify-email-employer-otp',
  BGV_REPORT_BULK_DOWNLOAD = 'bgvreportsbulkdownload',
  BGV_CONSENT_BULK_DOWNLOAD = 'bgvconsentbulkdownload',
  OPS_CDC_COMPLETION_NOTIFICATION_EMAIL = 'ops-cdc-completion-notification-email',
  OPS_ORDER_CREATION_NOTIFICATION = 'ops-order-notification-email',
  HIRING_APPLICATION_TRACKING_APPLIED = 'hiringapplicationtrackingapplied',
  HIRING_APPLICATION_TRACKING_SHORTLISTED = 'hiringapplicationtrackingshortlisted',
  HIRING_APPLICATION_TRACKING_INVITED = 'hiringapplicationtrackinginvited',
  HIRING_APPLICATION_TRACKING_INVITE_ACCEPTED = 'hiring_application_tracking_invite_accepted',
  HIRING_APPLICATION_TRACKING_MATCHED_TALENT = 'hiringapplicationtrackingmatchtalent',
  HIRING_APPLICATION_TRACKING_MATCHED_EMP = 'hiringapplicationtrackingmatchemployer',
  HIRING_ADMIN_PAYMENT_INITIATION_FAILED = 'hiringadminpaymentinitiationfailed',
  HIRING_PAYMENT_SUCCESSFUL = 'hiringemployerpaymentsuccessful',
  HIRING_PAYMENT_FAILED = 'hiringemployerpaymentfailed',
  HIRING_ADMIN_PAYMENT_FAILED = 'hiringadminpaymentfailed',
  HIRING_ORDER_FULFILLMENT_SUCCESSFUL = 'hiringemployerorderfulfillmentsuccessful',
  HIRING_ORDER_FULFILLMENT_FAILED = 'hiringadminorderfulfillmentfailed',
  HIRING_ADMIN_KYC_SUBMITTED = 'hiringadminkycsubmitted',
  HIRING_EMPLOYER_KYC_SUBMITTED_PROOFS = 'hiringemployerkycsubmittedproofs',
  HIRING_EMPLOYER_KYC_UNDER_REVIEW = 'hiringemployerkycunderreview',
  HIRING_EMPLOYER_KYC_ACCEPTED = 'hiringemployerkycacception',
  HIRING_EMPLOYER_KYC_REJECTION = 'hiringemployerkycrejection',
  HIRING_USER_CREATED = 'hiringnewuser',
  FEEDBACK_EMAIL = 'feedbackemail',
  FEEDBACK_SURVEY_EMAIL = 'surveyemail',
  TRIAL_REGISTRATION = 'trialregistration',
  TRIAL_REGISTRATION_USER = 'trialregistrationuser',

  TLNT_MISSED_EMP_VOICECALL = 'tlnt_missed_emp_voicecall',
  TLNT_EMP_UNLCKD_MOB = 'tlnt_emp_unlckd_mob',
  EMP_CALL_CNT_INCRSD = 'emp_call_cnt_incrsd',
  EMP_MSG_RCVD = 'emp_msg_rcvd',
  TLNT_MSG_RCVD = 'tlnt_msg_rcvd',
  HIRING_JOB_SUBMITTED_FOR_REVIEW = 'hiring_job_submitted_for_review',
  HIRING_JOB_ACCEPTED = 'hiring_job_accepted',
  HIRING_JOB_REJECTED = 'hiring_job_rejected',
  HIRING_TLNT_PUSHED_JOB = 'hiring_tlnt_pushed_job',
  HIRING_EMP_PUSHED_PRF = 'hiring_emp_pushed_prf',
  HIRING_EMP_REGISTERED = 'hiring_emp_registered',
  HIRING_EMP_EMAIL_OTP_RCVD = 'hiring_emp_email_otp_rcvd',
  HIRING_TLNT_REGISTERED = 'hiring_tlnt_registered',
  HIRING_EMPLOYER_PAYMENT_SUCCESSFUL = 'hiring_employer_payment_successful',
  PROV_JOB_POST_LIMIT_CROSSED_001 = 'Prov_job_post_limit_crossed_001',
  PROV_CALL_LIMIT_CROSSED_001 = 'Prov_call_limit_crossed_001',
  PROV_SHORTLIST_LIMIT_CROSSED_001 = 'Prov_shortlist_limit_crossed_001',
  PROV_CHAT_LIMIT_CROSSED_001 = 'Prov_chat_limit_crossed_001',
  HIRING_JOB_POSTING_PUBLISH = 'job_posting_published',
  HIRING_CHECK_EMAIL_OTP_VERIFICATION = 'hiring_check_email_otp_verification',
  HIRING_EMP_EMAIL_OTP_NOT_VERIFIED = 'hiring_emp_email_otp_not_verified',
  HIRING_TALENT_REGISTERED_MOB_OTP_NOT_VERIFIED = 'hiring_talent_registered_mob_otp_not_verified',
  HIRING_TALENT_REGISTERED = 'hiring_talent_registered',
  HIRING_EMP_PROFILE_COMPLETED = 'hiring_emp_profile_completed',
  HIRING_EMP_PROFILE_COMPLETED_JOB_NOT_POSTED = 'hiring_emp_profile_completed_job_not_posted',
  HIRING_EMP_PROFILE_NOT_COMPLETED = 'hiring_emp_profile_not_completed',
  HIRING_JOB_POSTING_INITIATED = 'job_posting_initiated',
  HIRING_EMP_BULK_RECENTLY_UNLOCKED_CALL_KYC_UNVERIFIED = 'hiring_emp_bulk_recently_unlokced_call_kyc_unverified',
  HIRING_EMP_BULK_RECENTLY_UNREAD_MESSAGES = 'hiring_emp_bulk_unread_messages',
  HIRING_TLNT_BULK_RECENTLY_UNREAD_MESSAGES = 'hiring_tlnt_bulk_unread_messages',
  HIRING_EMP_BULK_RECENTLY_LIMIT_EXHAUST_JOB_POSTING_LIMIT = 'hiring_emp_bulk_limit_exhaust_job_posting_limit',
  HIRING_EMP_BULK_RECENTLY_LIMIT_EXHAUST_CHAT_LIMIT = 'hiring_emp_bulk_limit_exhaust_chat_limit',
  HIRING_EMP_BULK_RECENTLY_LIMIT_EXHAUST_CALL_LIMIT = 'hiring_emp_bulk_limit_exhaust_call_limit',
  HIRING_EMP_BULK_RECENTLY_LIMIT_EXHAUST_SHORTLIST_LIMIT = 'hiring_emp_bulk_limit_exhaust_shortlist_limit',
  HIRING_TLNT_BULK_UNATTNEDED_INVITES = 'hiring_tlnt_bulk_unattneded_invites',
  HIRING_TLNT_BULK_NO_APPLICATION = 'hiring_tlnt_bulk_no_application',
  HIRING_EMP_BULK_RECENT_JOBS_NO_APPLICATION = 'hiring_emp_bulk_recent_jobs_no_application',
  HIRING_EMP_RECOMMENDATIONS = 'hiring_emp_recommendations',
  YOUTHHUB_TALENT_APPLICATION_RECIEVED = 'youthhub_talent_application_recieved',
}

export enum Event_Type {
  USER_INVITED = 'USER_INVITED', //Send email to user that he has invited
  USER_CREATED = 'USER_CREATED', //Subscribe user and send login details , welcome email
  SUPER_USER_CREATED = 'SUPER_USER_CREATED', // SUPER_USER_CREATED send welcome email
  ACCOUNT_CREATED = 'ACCOUNT_CREATED', //Subscribe account and send login details , welcome email
  CANDIDATE_CREATED = 'CANDIDATE_CREATED', //Subscribe candidate
  ORDER_CONFIRMED = 'ORDER_CONFIRMED', //Link send to candidate
  DATA_COLLECTION_SURVEY_LINK_TRIGGER = 'DATA_COLLECTION_SURVEY_LINK_TRIGGER', //Link send to candidate
  ORDER_SUCCESSFUL = 'ORDER_SUCCESSFUL', //Order details send to employer
  CANDIDATE_DATA_COLLECTION_STARTED = 'CANDIDATE_DATA_COLLECTION_STARTED', //Send email to employer data collection details in the Order
  CANDIDATE_DATA_COLLECTION_SUCCESSFUL = 'CANDIDATE_DATA_COLLECTION_SUCCESSFUL', //Send email to employer data collection details in the Order
  DATA_UPLOAD_SUCCESSFUL = 'DATA_UPLOAD_SUCCESSFUL', //Email send to employer that data uploaded to OPs
  PASSWORD_RESET = 'PASSWORD_RESET', //Link send for reset password
  CHECKOUT_INITIATION_COMPLETE = 'CHECKOUT_INITIATION_COMPLETE', //Schedule Email for employer that Order saved in draft not yet placed
  CANDIDATE_DATA_COLLECTION_COMPLETED = 'CANDIDATE_DATA_COLLECTION_COMPLETED', //Email send to Employer that candidate's data collection has been completed
  CANDIDATE_DATA_COLLECTION_CANCELLED = 'CANDIDATE_DATA_COLLECTION_CANCELLED', //On candidate data cancellation/ case cancellation email send to Employer
  INTERIM_REPORT_GENERATION_COMPLETED = 'INTERIM_REPORT_GENERATION_COMPLETED',
  FINAL_REPORT_GENERATION_COMPLETED = 'FINAL_REPORT_GENERATION_COMPLETED',
  DATA_INSUFFICIENCY = 'DATA_INSUFFICIENCY_EMAIL',
  USER_MESSAGE_TICKET_CREATE_REQUEST = 'USER_MESSAGE_TICKET_CREATE_REQUEST',
  USER_MESSAGE_TICKET_EXPIRE_REQUEST = 'USER_MESSAGE_TICKET_EXPIRE_REQUEST',
  KYC_CREATED_KYC = 'kyc_created_kyc',
  KYC_INITIATED_KYC = 'kyc_initiated_kyc',
  KYC_INITIATED_ATTEMPT = 'kyc_initiated_attempt',
  KYC_SUBMITTED_PROOFS = 'kyc_submitted_proofs',
  KYC_PROOF_VERIFICATION_INITIATED = 'kyc_proof_verification_initiated',
  KYC_KYC_VERIFIED = 'kyc_kyc_verified',
  KYC_KYC_FINISHED = 'kyc_kyc_finsihed',
  KYC_NEXT_PROOF_REQUESTED = 'kyc_next_proof_requested',
  KYC_CHECKED_IF_ALL_PROOFS_VERIFIED = 'kyc_checked_if_all_proofs_verified',
  KYC_NEXT_PROOF_IS_NOT_AVAILABLE = 'kyc_next_proof_is_not_available',
  KYC_VERIFIED_PROOF = 'kyc_verified_proof',
  KYC_REJECTED_PROOF = 'kyc_rejected_proof',
  KYC_ACCEPTED_PROOF = 'kyc_accepted_proof',
  KYC_APPEALED_AGAINST = 'kyc_appealed_against',
  KYC_REATTEMPTED_REQUEST = 'kyc_reattempted_request',
  KYC_REJECTED_APPEAL = 'kyc_rejected_appeal',
  KYC_ACCEPTED_APPEAL = 'kyc_accepted_appeal',
  KYC_SUBMITTED_ADDITIONAL_PROOF = 'kyc_submitted_additional_proof',
  HIRING_APPLICATION_TRACKING_APPLIED = 'hiring_application_tracking_applied',
  HIRING_APPLICATION_TRACKING_SHORTLISTED = 'hiring_application_tracking_shortlisted',
  HIRING_APPLICATION_TRACKING_INVITED = 'hiring_application_tracking_invited',
  HIRING_APPLICATION_TRACKING_INVITE_ACCEPTED = 'hiring_application_tracking_invite_accepted',
  HIRING_APPLICATION_TRACKING_MATCHED_TALENT = 'hiring_application_tracking_matched_talent',
  HIRING_APPLICATION_TRACKING_MATCHED_EMP = 'hiring_application_tracking_matched_emp',
  HIRING_EMP_REGISTERED = 'hiring_emp_registered',
  HIRING_TLNT = 'hiring_tlnt_registered',
  HIRING_MOB_OTP_GENERATED = 'hiring_mob_otp_generated',
  HIRING_MOB_OTP_VERIFIED = 'hiring_mob_otp_verified',
  HIRING_EMP_EML_OTP_RCVD = 'hiring_emp_email_otp_rcvd',
  HIRING_EMP_EML_OTP_VERIFIED = 'hiring_emp_email_otp_verified',
  HIRING_COMPANY_WENT_UNDER_REVIEW = 'hiring_company_went_under_review',
  HIRING_COMPANY_REJECTED = 'hiring_company_rejected',
  HIRING_COMPANY_VERIFIED = 'hiring_company_verified',
  HIRING_JOB_SUBMITTED_FOR_REVIEW = 'hiring_job_submitted_for_review',
  HIRING_JOB_ACCEPTED = 'hiring_job_accepted',
  HIRING_JOB_REJECTED = 'hiring_job_rejected',
  HIRING_JOB_UPDATE_REQUESTED_BY_ADMIN = 'hiring_job_update_requested_by_admin',
  HIRING_JOB_AUTO_APPROVED = 'hiring_job_auto_approved',
  JOB_POSTING = 'JOB_POSTING',
  MESSAGE_SENT = 'MESSAGE_SENT',
  JOB_UPDATE = 'JOB_UPDATE',
  TALENT_UPDATE = 'TALENT_UPDATE',
  HIRING_EMP_MSG_RCVD = 'emp_msg_rcvd',
  HIRING_TLNT_MSG_RCVD = 'tlnt_msg_rcvd',
  HIRING_PAYMENT_INITIATED = 'hiring_admin_payment_initiated',
  HIRING_PAYMENT_INITIATION_FAILED = 'hiring_admin_payment_initiation_failed',
  HIRING_PAYMENT_SUCCESSFUL = 'hiring_employer_payment_successful',
  HIRING_PAYMENT_FAILED = 'hiring_admin_payment_failed',
  HIRING_EMP_CALL_CNT_INCRSD = 'emp_call_cnt_incrsd',
  HIRING_ORDER_FULFILLMENT_SUCCESSFUL = 'hiring_employer_order_fulfillment_successful',
  HIRING_ORDER_FULFILLMENT_FAILED = 'hiring_admin_order_fulfillment_failed',
  HIRING_JOB_POSTING_INITIATED = 'job_posting_initiated',
  HIRING_JOB_POSTING_PUBLISH = 'job_posting_published',
  HIRING_JOB_POSTING_REPUBLISH = 'job_posting_republished',
  HIRING_JOB_POSTING_PUBLISHED_FIRST_JOB = 'job_posting_published_first_job',
  HIRING_JOB_POSTING_VERIFY = 'job_posting_verify',
  HIRING_JOB_POSTING_REJECT = 'job_posting_reject',
  HIRING_JOB_POSTING_GHOST = 'job_posting_ghost',
  HIRING_JOB_POSTING_HOLD = 'job_posting_hold',
  HIRING_COMPANY_PROFILE_ACTIVATE = 'company_profile_activate',
  HIRING_COMPANY_PROFILE_DEACTIVATE = 'company_profile_deactivate',
  HIRING_COMPANY_PROFILE_VERIFY = 'company_profile_verify',
  HIRING_COMPANY_PROFILE_REJECT = 'company_profile_reject',
  HIRING_COMPANY_PROFILE_HOLD = 'company_profile_hold',
  HIRING_COMPANY_PROFILE_GHOST = 'company_profile_ghost',
  HIRING_USER_CREATED = 'hiring_new_user',
  HIRING_TALENT_UPDATED = 'new_talent_update',
  HIRING_TALENT_CREATED = 'new_talent_created',
  HIRING_EMP_CREATION = 'new_emp_update',
  HIRING_TALENT_MISSED_EMP_VOICECALL = 'tlnt_missed_emp_voicecall',
  HIRING_TALENT_EMP_UNLCKED_MOB = 'tlnt_emp_unlckd_mob',
  HIRING_EMP_CALL_EXHT = 'Prov_call_limit_crossed_001',
  HIRING_EMP_CHAT_EXHT = 'Prov_chat_limit_crossed_001',
  HIRING_EMP_JOBPOST_EXHT = 'Prov_job_post_limit_crossed_001',
  HIRING_EMP_SHRT_EXHT = 'Prov_shortlist_limit_crossed_001',
  HIRING_EMP_PUSH_PRF = 'hiring_emp_pushed_prf',
  HIRING_TLNT_PUSH_JOB = 'hiring_tlnt_pushed_job',
  HIRING_TALENT_CREATION = 'hiring_talent_creation',
  HIRING_CHECK_EMAIL_OTP_VERIFICATION = 'HIRING_CHECK_EMAIL_OTP_VERIFICATION',
  HIRING_EMP_EMAIL_OTP_NOT_VERIFIED = 'hiring_emp_email_otp_not_verified',
  HIRING_TALENT_REGISTERED_MOB_OTP_NOT_VERIFIED = 'hiring_talent_registered_mob_otp_not_verified',
  HIRING_TALENT_REGISTERED = 'hiring_talent_registered',
  HIRING_EMP_PROFILE_COMPLETED = 'hiring_emp_profile_completed',
  HIRING_EMP_PROFILE_COMPLETED_JOB_NOT_POSTED = 'hiring_emp_profile_completed_job_not_posted',
  HIRING_EMP_PROFILE_NOT_COMPLETED = 'hiring_emp_profile_not_completed',
  HIRING_EMP_BULK_RECENTLY_UNLOCKED_CALL_KYC_UNVERIFIED = 'hiring_emp_bulk_recently_unlokced_call_kyc_unverified',
  HIRING_EMP_BULK_RECENTLY_UNREAD_MESSAGES = 'hiring_emp_bulk_unread_messages',
  HIRING_TLNT_BULK_RECENTLY_UNREAD_MESSAGES = 'hiring_tlnt_bulk_unread_messages',
  HIRING_EMP_BULK_RECENTLY_LIMIT_EXHAUST_JOB_POSTING_LIMIT = 'hiring_emp_bulk_limit_exhaust_job_posting_limit',
  HIRING_EMP_BULK_RECENTLY_LIMIT_EXHAUST_CHAT_LIMIT = 'hiring_emp_bulk_limit_exhaust_chat_limit',
  HIRING_EMP_BULK_RECENTLY_LIMIT_EXHAUST_CALL_LIMIT = 'hiring_emp_bulk_limit_exhaust_call_limit',
  HIRING_EMP_BULK_RECENTLY_LIMIT_EXHAUST_SHORTLIST_LIMIT = 'hiring_emp_bulk_limit_exhaust_shortlist_limit',
  HIRING_TLNT_BULK_UNATTNEDED_INVITES = 'hiring_tlnt_bulk_unattneded_invites',
  HIRING_TLNT_BULK_NO_APPLICATION = 'hiring_tlnt_bulk_no_application',
  HIRING_EMP_BULK_RECENT_JOBS_NO_APPLICATION = 'hiring_emp_bulk_recent_jobs_no_application',
  HIRING_EMP_RECOMMENDATIONS = 'hiring_emp_recommendations',
  YOUTHHUB_TALENT_APPLICATION_RECIEVED = 'youthhub_talent_application_recieved',
}

export enum HIRING_SUBSCRIPTION_ACTIVITY {
  USE_FEATURE_JOB_POSITNG = 'USE_FEATURE_JOB_POSITNG',
  USE_FEATURE_APPLICATION = 'USE_FEATURE_APPLICATION',
  USE_FEATURE_APP_TRACKING_APPLY = 'USE_FEATURE_APP_TRACKING_APPLY',
  USE_FEATURE_APP_TRACKING_SHORTLIST = 'USE_FEATURE_APP_TRACKING_SHORTLIST',
  USE_FEATURE_APP_TRACKING_INVITE = 'USE_FEATURE_APP_TRACKING_INVITE',
  USE_FEATURE_APP_TRACKING_TEXT_MESSAGE = 'USE_FEATURE_APP_TRACKING_TEXT_MESSAGE',
  USE_FEATURE_APP_TRACKING_VOICE_CALL_UNLOCK = 'USE_FEATURE_APP_TRACKING_VOICE_CALL_UNLOCK',
}

export enum HIRING_SUBSCRIPTION_PACKAGE_NAME {
  FREEMIUM_UNVERIFIED_EMPLOYER = 'freemium_unverified_prekyc_03102024',
  FREEMIUM_VERIFIED_EMPLOYER = 'freemium_verified_10_call',
  FREEMIUM_UNVERIFIED_EMPLOYER_10_CALLS = 'freemium_unverified_10_calls',
}

export enum MinimumQualification {
  TENTH = 'tenth',
  TWELTH = 'twelth',
  DIPLOMA = 'diploma',
  GRADUATE = 'graduate',
  POST_GRADUATE = 'post_graduate',
}
export enum additionalBenefits {
  CAB = 'Cab',
  MEAL = 'Meal',
  PF = 'PF',
  INSURANCE = 'Insurance',
  MEDICAL_ALLOWANCE = 'Medical Allowance',
  VEHICLE_ALLOWANCE = 'Vehicle Allowance',
}

export enum HiringJobPostingStatus {
  DRAFT = '5',
  ACTIVE = '1',
  UNPUBLISHED = '0',
  UNDER_REVIEW = '4',
  CLOSE = '10',
  PAUSED = '30',
  EXPIRED = '2040',
  DELETED_BY_TEAM_TALENT_POSTED_JOB = '4010',
  DRAFT_DELETED = '3020',
  LIVE_GHOSTED_BY_TEAM = '2070',
}

export enum OLD_API_JOB_TRACKING_STATUS {
  drafted = 'DRAFT',
  ghosted = 'LIVE_GHOSTED_BY_TEAM',
  expired = 'EXPIRED',
  live = 'ACTIVE',
  unpublished = 'UNPUBLISHED',
  close = 'CLOSE',
  paused = 'PAUSED',
  under_review = '',
  alljobs = 'ALL_JOBS',
}

export enum HiringJobPostingVerificationStatus {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  IN_PROGRESS = 'in_progress',
  UNVERIFIED = 'unverified',
}
export enum PermissionAction {
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  AGREEMENT = 'AGREEMENT',
}

// TODO: This should be part of master data/lookup data
export enum Feature {
  ACCOUNT = 'ACCOUNT',
  PRODUCT = 'PRODUCT',
  CHECKOUT = 'CHECKOUT',
  CHECKOUT_INITIATION = 'CHECKOUT_INITIATION',
  ORDER = 'ORDER',
  PACKAGE = 'PACKAGE',
  USER = 'USER',
  USER_ROLE = 'USER_ROLE',
  CATEGORY = 'CATEGORY',
  FEATURE_OBJECT = 'FEATURE_OBJECT',
  DATA_COLLECTION = 'DATA_COLLECTION',
  JOB_ROLE = 'JOB_ROLE',
  PERMISSION = 'PERMISSION',
  PREFERENCE = 'PREFERENCE',
  PRODUCT_LISTING = 'PRODUCT_LISTING',
  TEAM = 'TEAM',
  //TODO CHECK ALL FEATURE
  BILLING = 'BILLING',
  ANALYTIC = 'ANALYTIC',
  BGV_REPORT = 'BGV_REPORT',
  CANDIDATE = 'CANDIDATE',
  OPS = 'OPS',
  DATA_MAPPING = 'DATA_MAPPING',
  DOCUMENT_MAPPING = 'DOCUMENT_MAPPING',
  SUBSIDIARIES = 'SUBSIDIARIES',
}

// TODO: This should be part of master data/lookup data
export enum ROLE_PERMISSION_CATEGORY {
  USER_MANAGEMENT = 'User Management',
  TEAM_MANAGEMENT = 'Team Management',
  PACKAGE_MANAGEMENT = 'Package Management',
  COMPANY_PROFILE = 'Company Profile',
  INITIATE_VERIFICATION = 'Initiate Verification',
  JOB_ROLE = 'Job Role',
  // ANALYTICS = 'Analytics',
  BILLING_MANAGEMENT = 'Billing & Payments',
  CANDIDATE_MANAGEMENT = 'Candidate Management',
  ORDER_MANAGEMENT = 'Order Management',
  ANALYTICS = 'Reports & Analytics',
  OPS_MANAGEMENT = 'Ops Management',
  BULK_UPLOAD = 'Bulk Upload',
  // GROUP_OF_COMPANIES = 'GROUP_OF_COMPANIES',
  PREFERENCE_MANAGEMENT = 'Preference Management',
  GROUP_OF_COMPANIES = 'Group of companies',
}

export enum ENUM_COMPANY_STATUS {
  ACTIVE = 100,
  CREATED = 1010,
  GHOSTED = 2070,
  PAUSED = 200,
  SWITCHED_TO_TALENT = 2010,
}

export enum ENUM_COMPANY_VERIFICATION_STATUS {
  ACCPTED = 'accepted',
  UNVERFIED = 'unverified',
  REJECTED = 'rejected',
  REVERIFY_IN_PROGRESS = 'reverify_in_progress',
  IN_PROGRESS = 'in_progress',
}
export enum CATEGORY_ACCESS_LEVEL {
  ALLOW_ACCESS = 'ALLOW_ACCESS',
  NO_ACCESS = 'NO_ACCESS',
  VIEW_ONLY = 'VIEW_ONLY',
}

export enum ENUM_HIRING_EMP_EMAIL_IS_VERIFIED {
  YES = 'YES',
  NO = 'NO',
}
export enum ENUM_HIRING_EMP_STATUS {
  VERIFIED = 100,
  UNVERIFIED = 1010,
}
export enum ENUM_HIRING_EMP_EMAIL_VERIFICATION_STATUS {
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified',
  IN_PROGRESS = 'in_progress',
}

export enum COMPANY_SETTINGS_LABELS {
  COMPANY_PROFILE = 'Company profile',
  USER_TEAMS = 'User & Teams',
  ROLE_MANAGEMENT = 'Role management',
  PACKAGES = 'Packages',
  PREFERENCE = 'Preferences',
}

export enum ADDED_TYPE {
  UPLOAD = 'UPLOAD',
  MANUAL = 'MANUAL',
}

export enum SCREEN_SIZE {
  LARGE = 'lg',
  MEDIUM = 'md',
  EXTRALARGE = 'xl',
}

// Enum to map permissions with UI Components -> Name of permission to get array of permission from a map
export enum UI_LEVEL_PERMISSION {
  // COMPANY / ACCOUNT
  ADD_UPDATE_COMPANY_LOGO = 'ADD_UPDATE_COMPANY_LOGO',
  VIEW_COMPANY_PROFILE = 'VIEW_COMPANY_PROFILE',
  VIEW_COMPANY_AGGREEMENT = 'VIEW_COMPANY_AGGREEMENT',
  // TEAM MANAGEMENT
  CREATE_TEAM = 'CREATE_TEAM',
  UPDATE_TEAM = 'UPDATE_TEAM',
  MANAGE_TEAM_MEMBERS = 'MANAGE_TEAM_MEMBERS',
  DELETE_TEAM = 'DELETE_TEAM',
  VIEW_TEAM_LIST = 'VIEW_TEAM_LIST',
  VIEW_TEAM_DETAILS = 'VIEW_TEAM_DETAILS',
  // ORDER MANAGEMENT
  CREATE_ORDER = 'CREATE_ORDER',
  // PACKAGE MANAGEMENT
  VIEW_PACKAGE_LIST = 'VIEW_PACKAGE_LIST',
}

// TODO: This should be part of master key/lookup data
export enum ROLE_PERMISSION_CATEGORY_KEY {
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  TEAM_MANAGEMENT = 'TEAM_MANAGEMENT',
  PACKAGE_MANAGEMENT = 'PACKAGE_MANAGEMENT',
  PREFERENCE_MANAGEMENT = 'PREFERENCE_MANAGEMENT',
  COMPANY_PROFILE = 'COMPANY_PROFILE',
  INITIATE_VERIFICATION = 'INITIATE_VERIFICATION',
  JOB_ROLE = 'JOB_ROLE',
  // ANALYTICS = 'Analytics',
  BILLING_MANAGEMENT = 'BILLING_MANAGEMENT',
  CANDIDATE_MANAGEMENT = 'CANDIDATE_MANAGEMENT',
  ORDER_MANAGEMENT = 'ORDER_MANAGEMENT',
  REPORTS_ANALYTICS = 'REPORTS_ANALYTICS',
  OPS_MANAGEMENT = 'OPS_MANAGEMENT',
  BULK_UPLOAD = 'BULK_UPLOAD',
  GROUP_OF_COMPANIES = 'GROUP_OF_COMPANIES',

  //INITIATE_VERIFICATION = 'Initiate Verification',

  // VERIFICATION_MANAGEMENT = ' Verification Management',
}

// TODO: This should be part of master key/lookup data
export enum FeatureKEY {
  ACCOUNT = 'ACCOUNT',
  PRODUCT = 'PRODUCT',
  CHECKOUT = 'CHECKOUT',
  CHECKOUT_INITIATION = 'CHECKOUT_INITIATION',
  ORDER = 'ORDER',
  PACKAGE = 'PACKAGE',
  USER = 'USER',
  USER_ROLE = 'USER_ROLE',
  CATEGORY = 'CATEGORY',
  FEATURE_OBJECT = 'FEATURE_OBJECT',
  DATA_COLLECTION = 'DATA_COLLECTION',
  JOB_ROLE = 'JOB_ROLE',
  PERMISSION = 'PERMISSION',
  PREFERENCE = 'PREFERENCE',
  PRODUCT_LISTING = 'PRODUCT_LISTING',
  TEAM = 'TEAM',
  //TODO CHECK ALL FEATURE
  BILLING = 'BILLING',
  ANALYTIC = 'ANALYTIC',
  BGV_REPORT = 'BGV_REPORT',
  CANDIDATE = 'CANDIDATE',
  SUBSIDIARIES = 'SUBSIDIARIES',
}

export enum PushNotificationStatus {
  PENDING = 'PENDING',
  TRIGGERED = 'TRIGGERED',
  INPROGRESS = 'INPROGRESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  NOT_FOUND = 'not_exist_in_db',
}

export enum PushNotificationLog {
  NOTREGISTERED = 'NotRegistered',
  RECORD_NOT_FOUND = 'Record not found',
  RETRYING = 'retrying',
}

export enum NovuNotificationStatus {
  INPROGRESS = 'processed',
}

//DataCollection Field Type
export enum FIELD_TYPE {
  DATACOLLECTION = 'DataCollection',
  REPORT = 'Report',
}

//Product Types
export enum PRODUCT_TYPES_PRIORITY {
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
}
export enum RecruitmentUserRoleEncoded {
  TALENT = '0',
  EMPLOYER = '1',
  NO_ROLE = 'null',
}
export enum RecruitmentUserRole {
  ADMIN = 'admin',
  TALENT = 'talent',
  EMPLOYER = 'employer',
  NO_ROLE = 'no_role',
}
export enum RecruiterUserSource {
  GIGIN = 'gigin',
  YOUTH_HUB = 'youthhub',
}
export enum OldUserRole {
  SEEKER = 'seeker', // using in the old data
  PROVIDER = 'provider', //using in the old data
}
export enum deleteMessageProviderAndSeeker {
  deleted = '1',
  not_deleted = '0',
}
export enum TALENT_ROLE_EXPERTISE {
  UNKNWON = 'null',
  FRESHER = 'fresher',
  ONE_TO_THREE_YEAR = '1to3year',
  LESS_THAN_YEAR = 'lessthanyear',
  MORE_THAN_THREE_YEAR = 'morethan3year',
}

export enum GENDER {
  MALE = 'male',
  FEMALE = 'female',
  BOTH = 'both',
  OTHERS = 'others',
}

export enum COMPENSATION_TYPE {
  RANGE = 'range',
  EXACT = 'exact',
}

export enum COMPENSATION_UNIT {
  RUPEE = 'rupee',
  DOLLAR = 'dollar',
}

export enum TALENT_PROFILE_COMPLETION_STATUS {
  INCOMPLETE = 'incomplete',
  COMPLETE = 'complete',
}

export enum LANGUAGE {
  hindi = 'hi',
  english = 'en',
  bangala = 'bn',
  kannada = 'kn',
  malayalam = 'ml',
  marathi = 'mr',
  tamil = 'ta',
  telugu = 'te',
}

export enum LANGUAGES_LIST {
  HINDI = 'hi',
  ENGLISH = 'en',
  BANGALA = 'bn',
  KANNADA = 'kn',
  MALAYALAM = 'ml',
  MARATHI = 'mr',
  TAMIL = 'ta',
  TELUGU = 'te',
}

export enum LANGUAGE_PROFICIENCY {
  BASIC = 'basic',
  CONVERSATIONAL = 'conversational',
  FLUENT = 'fluent',
  NATIVE = 'native',
}

export enum NUDGE_SCOPE {
  DASHBOARD = 'dashboard',
  // Add other scopes as necessary...
}

export enum TENURE_STATUS {
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

export enum MOBOTP_STATUS {
  VERIFIED = 'verified',
  EXPIRED = 'expired',
  CREATED = 'created',
}

export enum MOBOTP_CONTEXT {
  RECRUITER_APP = 'recruiter_app',
  COMMON_APP = 'common_app',
}
export enum MOBOTP_SOURCE {
  TWO_FACTOR = '2factor',
}
export enum MOBOTP_ACTIVITY_TYPE {
  GENERATING = 'mob_otp_generating',
  VERIFYING = 'mob_otp_verifing',
  EXPIRING = 'mob_otp_expiring',
  VERIFICATION_FAILED = 'mob_otp_verification_failed',
}
export enum EMAIL_ACTIVITY_TYPE {
  GENERATING = 'email_otp_generating',
  VERIFYING = 'email_otp_verifing',
  EXPIRING = 'email_otp_expiring',
  VERIFICATION_FAILED = 'email_otp_verification_failed',
}
export enum RecruitmentUserOTPVerificationStatus {
  VERIFIED = 'ACTIVE',
  NON_VERIFIED = 'INACTIVE',
}

//Used in messaging in wow
export enum MessageType {
  NOTICE = 'NOTICE',
  NORMAL = 'NORMAL',
  WARNING = 'WARNING',
  WARNING_TEMPLATE = 'WARNING_TEMPLATE',
}

export enum OfferStatus {
  Pending = 'Pending',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  NoOffer = 'NOOFFER',
}

export enum jobWorkingType {
  full_time_work = 'full_time',
  part_time_work = 'part_time',
  contract = 'contract_type',
}

export enum CHAT_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  UNKNWON = 'UNKNWON',
}
// Define any other necessary enums based on your application's needs...

export enum FEATURE_TYPE {
  JOB_POSTING = 'job_posting',
  TEXT_MESSAGES = 'text_message',
  INVITE_TALENT = 'invite_talent',
  SHORTLIST_TALENT = 'shortlist_talent',
  UNLOCK_VOICE_CALL = 'unlock_voice_call',
  UNLOCK_CALLS_CUSTOM = 'unlock-calls-custom',
  DESCRIPTION_GENERATION = 'description_generation',
}

export enum ENUM_REDIS_COLLECTION_PREFIX {
  EMPLOYER_SUBSCRIBED_PACKAGE = 'ESPK-',
  JOB_DETAILS = 'JP-',
}
export enum RECRUITEMENT_ACTION {
  POST_JOB = 'post_job',
  INITIATE_JOB = 'initiate_job',
  INITIATE_VOICE_CALL = 'initiate_voice_call',
  SEND_TEXT_MESSAGE = 'send_text_message',
}

export enum SUBSCRIPTION_PACKAGE_TYPE {
  GLOBAL = 'global',
  CUSTOM = 'custom',
  ADDON = 'addon',
  PAID = 'paid',
  FREE_GLOBAL = 'free_global',
}

export enum SUBSCRIPTION_PACKAGE_IS_ACTIVE {
  YES = 'yes',
  NO = 'no',
}
export enum TIME_DURATION_TYPE {
  DAY = 'day',
  MONTH = 'month',
  HOUR = 'hour',
}

export enum CURRENCY {
  INR = 'INR',
}

export enum videoQuestionType {
  Talent = 'Talent',
  Job = 'Job',
}

export enum recommendation_motivation_marker {
  ACTIVELY_HIRING = 'Actively hiring',
  QUICK_RESPONSE = 'Quick Response',
  BEST_MATCH = 'best match',
  JOB_OPEN = 'Open Position',
}

export enum elasticSearchContext {
  roles = 'roles',
  hashtags = 'hashtags',
  jobposting = 'jobposting',
  onboarding_search = 'onboarding-search',
  location = 'location',
}

export enum ENUM_JOB_POST_ELASTIC_SEARCH_FIELDS {
  ALTERNATE_COMPENSATION = 'alternateCompensation.range.end',
  ROLES = 'roles.name.en',
  SKILLS = 'hashTags.skillName.en',
  LOCATION = 'locations.city',
  WORKING_TYPE = 'alternateWorkingType',
  WORK_LOCATION = 'alternateWorkLocation',
}

export enum RecordingStatus {
  Completed = 'Completed',
  Pending = 'Pending',
}

export enum VideoStatus {
  Uploaded = 'Uploaded',
  Pending = 'Pending', // Default value
}

export enum SimplifiedJobPostingWorkingLocation {
  WORK_FROM_HOME = 'work-from-home',
  OFFICE_WORKING = 'office-working',
  FIELD_JOB = 'field-job',
  HYBRID = 'hybrid',
}

export enum JobPostingWorkingLocation {
  WORK_FROM_HOME = 'wfh',
  OFFICE_WORKING = 'ow',
  FIELD_JOB = 'fld_jb',
  HYBRID = 'hybrid',
}
export enum EnumApiSource {
  WATI = 'WATI',
}
//KYC ENUMS
export enum ENUM_MODE_OF_TRANSPORT {
  CAR_POOL = 'Car-Pool',
  METRO = 'METRO',
  BIKE = 'BIKE',
  BUS = 'BUS',
}

export enum DISTANCE_MEASURING_UNITS {
  METERS = 'meters',
  KM = 'km',
  MILES = 'miles',
}

export enum EnumKycStatus {
  DRAFT = 'Draft',
  CREATED = 'created',
  KYC_INITIATED = 'kyc_initiated',
  AWAITING_PROOFS = 'awaiting_proofs',
  PROOFS_SUBMITTED = 'proofs_submitted',
  KYC_IN_PROGRESS = 'kyc_in_progress',
  AWAITING_NEXT_PROOF = 'awaiting_next_proof',
  PROOF_FETCHED = 'proof_fetched',
  COMPLETED = 'completed',
  AWAITING_PROOF_RESUBMIT = 'awaiting_proof_resubmit',
  KYC_VERIFIED = 'kyc_verified',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  APPEAL_PENDING = 'appeal_pending',
  KYC_REINITIATED = 'kyc_reinitiated',
}

export enum EnumKycProofStatus {
  PROOF_VERIFICATION_INITIATED = 'proof_verification_initiated',
  AWAITING_NEXT_PROOF = 'awaiting_next_proof',
  PROOF_FETCHED = 'proof_fetched',
  PROOF_VERIFICATION_COMPLETED = 'proof_verification_completed',
  SUBMITTED = 'submitted',
}

export enum EnumKycVerificationStatus {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  UNVERIFIED = 'unverified',
}
export enum EnumKycVerificationAction {
  ACCEPT = 'accept',
  REJECT = 'reject',
}

export enum EnumKycAttemptStatus {
  SUBMIT = 'submit',
  PROCESSING = 'processing',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export enum EnumKycDocType {
  PAN = 'pan',
  AADHAR = 'aadhar',
}
export enum EnumKycDocCategory {
  ID_PROOF = 'id_proof',
}

// Enum for field names
export enum KycRejectionReasonType {
  INCOMPLETE_OR_MISSING_INFORMATION = 'Incomplete Info',
  EXPIRED_DOCUMENTS = 'Expired Docs',
  INFORMATION_MISMATCH = 'Info Mismatch',
  POSSIBLE_FAKE_DOCUMENTS = 'Possible Fake Docs',
  UNACCEPTED_DOCUMENTS = 'Unaccepted Docs',
  BLURRY_PICTURES = 'Blurry Pics',
  IDENTITY_UNCERTAINTY = 'Identity Doubt',
  ADDRESS_VERIFICATION_ISSUE = 'Address Issue',
  PAST_RULE_VIOLATIONS = 'Past Issues',
  LACK_OF_COOPERATION = 'No Cooperation',
  WRONG_DOCUMENT = 'Wrong Document',
  GHOST = 'Ghost',
  TALENT_ROLE = 'TalentRole',
}

// Enum for field values
export enum KycRejectionReasonDescription {
  INCOMPLETE_OR_MISSING_INFORMATION = 'Important details are lacking in the documents.',
  EXPIRED_DOCUMENTS = 'Documents are outdated; submit updated ones.',
  INFORMATION_MISMATCH = 'Information on the documents does not align with the provided details.',
  POSSIBLE_FAKE_DOCUMENTS = 'Concerns about the authenticity of provided documents.',
  UNACCEPTED_DOCUMENTS = 'Documents could not be authenticated.',
  BLURRY_PICTURES = 'ID photos are unclear; provide clear images.',
  IDENTITY_UNCERTAINTY = 'Uncertainties regarding the provided information.',
  ADDRESS_VERIFICATION_ISSUE = 'Issues with address verification; ensure accuracy.',
  PAST_RULE_VIOLATIONS = 'Previous instances of rule violations or fraudulent activities.',
  LACK_OF_COOPERATION = 'Insufficient assistance during the verification process.',
  WRONG_DOCUMENT = 'Wrong Document has been uploaded',
  GHOST = 'Resubmit All Documents to Verify',
  TALENT_ROLE = 'User is converted to Talent',
}

export const kycRejectionMapping: { [key in KycRejectionReasonType]: string } = {
  [KycRejectionReasonType.INCOMPLETE_OR_MISSING_INFORMATION]:
    KycRejectionReasonDescription.INCOMPLETE_OR_MISSING_INFORMATION,
  [KycRejectionReasonType.EXPIRED_DOCUMENTS]: KycRejectionReasonDescription.EXPIRED_DOCUMENTS,
  [KycRejectionReasonType.INFORMATION_MISMATCH]: KycRejectionReasonDescription.INFORMATION_MISMATCH,
  [KycRejectionReasonType.POSSIBLE_FAKE_DOCUMENTS]: KycRejectionReasonDescription.POSSIBLE_FAKE_DOCUMENTS,
  [KycRejectionReasonType.UNACCEPTED_DOCUMENTS]: KycRejectionReasonDescription.UNACCEPTED_DOCUMENTS,
  [KycRejectionReasonType.BLURRY_PICTURES]: KycRejectionReasonDescription.BLURRY_PICTURES,
  [KycRejectionReasonType.IDENTITY_UNCERTAINTY]: KycRejectionReasonDescription.IDENTITY_UNCERTAINTY,
  [KycRejectionReasonType.ADDRESS_VERIFICATION_ISSUE]: KycRejectionReasonDescription.ADDRESS_VERIFICATION_ISSUE,
  [KycRejectionReasonType.PAST_RULE_VIOLATIONS]: KycRejectionReasonDescription.PAST_RULE_VIOLATIONS,
  [KycRejectionReasonType.LACK_OF_COOPERATION]: KycRejectionReasonDescription.LACK_OF_COOPERATION,
  [KycRejectionReasonType.WRONG_DOCUMENT]: KycRejectionReasonDescription.WRONG_DOCUMENT,
  [KycRejectionReasonType.GHOST]: KycRejectionReasonDescription.GHOST,
  [KycRejectionReasonType.TALENT_ROLE]: KycRejectionReasonDescription.TALENT_ROLE,
};

export enum EnumEmployerKycAcceptableDocs {
  cin_number = 'CIN Number Card',
  company_gstin = 'Company GSTIN',
  company_pan = 'Company PAN Card',
  other = 'Other',
  shop_establishment = 'Shop Establishment ID',
  udyog_aadhar = 'Company Udyog Aadhar',
  company_id_card = 'Company Id Card',
  company_offer_letter = 'Company Offer letter',
}
// pricingAdjustmentType.enum.ts
export enum PricingAdjustmentTypeEnum {
  TAX = 'TAX',
  DISCOUNT = 'DISCOUNT',
  VENDOR_CHARGE = 'VENDOR_CHARGE',
}

// pricingAdjustmentMethod.enum.ts
export enum PricingAdjustmentMethodEnum {
  FIX = 'FIX',
  RATE = 'RATE',
}

export enum State {
  all = 'all',
  Draft = 'ST_DRFT',
  Applied = 'ST_APPLD',
  shortlisted = 'ST_SHRTLSTD',
  askedinfo = 'ST_EM_ASK_INFO',
  interviewing = 'ST_MTCH',
  connect_in_progress = 'ST_CONN',
  offer_revoked = 'ST_OFFR_REJ',
  offer_declined = 'ST_OFFR_REJ',
  offer_initiated = 'ST_OFFRD',
  declined = 'ST_EM_REJ',
  revoked = 'ST_NT_INTRS',
  match = 'ST_MTCH',
}

export enum Actions {
  AC_EM_CNT = 'CONNECT_WITH_CANDIDATE',
  AC_EM_SHRTLT = 'SHORTLIST_CANDIDATE',
  AC_EM_SHRTLT_CALL = 'SHORTLIST_CANDIDATE_CALL',
  AC_EM_REJ = 'REJECT_CANDIDATE',
  AC_EM_ASK = 'ASK_FOR',
  AC_TL_NT_INTR = 'NOT_INTERESTED_ANYMORE',
  AC_TL_CNT_FL = 'CONNECT_AND',
  AC_EM_SCH_CN_INTW = 'SCHEDULE_AND_CONDUCT_INTERVIEW',
  AC_TL_APPLY = 'APPLY',
  AC_TL_SCH_TK_INTW = 'SCHEDULE_AND_TAKE',
  AC_EM_SND_FDBK = 'SEN',
  AC_EM_OFRD = 'OFFERED',
  AC_TL_OFR_ACT = 'OFFER_ACCEPTED',
  AC_TL_OFR_REJ = 'OFFER_REJECTED',
  AC_TL_OFR_RVKD = 'OFFER_REVOKED',
  AC_EMP_INVITE_TALENT = 'invited_talent',
  AC_TL_ACCEPT_INVITE = 'accept_invitation',
  AC_TL_IGNORE_INVITE = 'ignore_invitation',
  AC_EM_HIRE_TALENT = 'hire_talent',
}

export enum Status {
  ST_ALL = 'all',
  ST_APPLD = 'APPLIED',
  ST_DRFT = 'DRAFT',
  ST_SHRTLSTD = 'SHORTLISTED',
  ST_EM_ASK_INFO = 'EMPLOYER_ASKED_MORE_INFO',
  ST_MTCH = 'MATCHED',
  ST_NT_INTRS = 'NOT_INTERESTED',
  ST_EM_REJ = 'REJECTED_BY_EMPLOYER',
  ST_TL_REJ = 'REJECTED_BY_CANDIDATE',
  ST_CONN = 'CONNECT_IN_PROGRESS',
  ST_EVAL = 'EVAL_IN_PROGRESS',
  ST_SLCT = 'SELECTED',
  ST_ON_HOLD = 'ON_HOLD',
  ST_OFFRD = 'OFFERED',
  ST_OFFR_ACC = 'OFFER_ACCEPTED',
  ST_OFFR_REJ = 'OFFER_REJECTED',
  ST_OFFR_RVKD = 'OFFER_REVOKED',
  ST_JND = 'JOINT',
  ST_CLSD = 'CLOSED',

  ST_INVTION_DRFT = 'invitation_is_in_draft',
  ST_INVITED_TLNT = 'invited_talent',
  ST_ACCEPTED = 'accepted',
  ST_ALRDY_INVITED = 'already_invited',
  ST_ALRDY_ACCEPTED_INVITATION = 'already_accepted_invitation',

  ST_NULL = 'null',
}

export enum ApplicationStatus {
  ST_DRFT = 'DRAFT',
  ST_INVITE = 'INVITE',
  ST_APPLIED = 'APPLIED',
  ST_ACCEPT_INVITATION = 'ACCEPT_INVITATION',
  ST_IGNORE_INVITATION = 'IGNORE_INVITATION',
  ST_REJECT_APPLICATION = 'REJECT_APPLICATION',
  ST_SHORTLISTED = 'SHORTLISTED',
  ST_SCHEDULE_INTERVIEW = 'SCHEDULE_INTERVIEW',
  ST_ACCEPT_INTERVIEW = 'ACCEPT_INTERVIEW',
  ST_REJECT_INTERVIEW = 'REJECT_INTERVIEW',
  ST_RELEASE_OFFER = 'RELEASE_OFFER',
  ST_REJECT_OFFER = 'REJECT_OFFER',
  ST_ACCEPT_OFFER = 'ACCEPT_OFFER',
  ST_SUSPICIOUS_ACTIVITY_DETECTED = 'SUSPICIOUS_ACTIVITY_DETECTED',
  ST_HIRE_APPLICANT = 'HIRE_APPLICANT',
}

export enum ApplicationActionType {
  INVITE = 'invite',
  APPLY = 'apply',
  ACCEPT_INVITATION = 'accept_invitation',
  IGNORE_INVITATION = 'ignore_invitation',
  REJECT_APPLICATION = 'reject_application',
  SHORTLIST = 'shortlist',
  INTERVIEW = 'interview',
  ACCEPT_INTERVIEW = 'accept_interview',
  REJECT_INTERVIEW = 'reject_interview',
  HIRE_APPLICANT = 'hire_applicant',
}

export enum CallStatus {
  ATTEMPTED = 'ATTEMPTED',
  MISSED = 'MISSED',
  CONNECTED = 'CONNECTED',
}
export enum CallType {
  AUDIO_CALL = 'AUDIO_CALL',
}
export enum InteractionType {
  AUDIO_CALL = 'AUDIO_CALL',
  MESSAGE_TEXT = 'MESSAGE_TEXT',
}

export enum EnumTalentRecommendationsForJob {
  talent_Limit = 100,
}
export enum EnumJobRecommendationForTalent {
  job_limit = 100,
}
export enum OLD_API_APPLICATION_TRACKING_STATUS {
  ST_ALL = 'all',
  ST_SHORTLISTED = 'shortlisted',
  ST_APPLIED = 'applied',
  ST_CONTACT_IN_PROGRESS = 'connect_in_progress',
  ST_DRAFTED = 'drafted',
}

export const OLD_TO_NEW_APP_TRACKING_STATUS_MAP: { [key: string]: Status } = {
  [OLD_API_APPLICATION_TRACKING_STATUS.ST_ALL]: Status.ST_ALL,
  [OLD_API_APPLICATION_TRACKING_STATUS.ST_SHORTLISTED]: Status.ST_INVITED_TLNT,
  [OLD_API_APPLICATION_TRACKING_STATUS.ST_APPLIED]: Status.ST_APPLD,
  [OLD_API_APPLICATION_TRACKING_STATUS.ST_CONTACT_IN_PROGRESS]: Status.ST_SHRTLSTD,
  [OLD_API_APPLICATION_TRACKING_STATUS.ST_DRAFTED]: Status.ST_DRFT,
};

export const NEW_TO_OLD_APP_TRACKING_STATUS_MAP: { [key: string]: OLD_API_APPLICATION_TRACKING_STATUS } = {
  [Status.ST_ALL]: OLD_API_APPLICATION_TRACKING_STATUS.ST_ALL,
  [Status.ST_SHRTLSTD]: OLD_API_APPLICATION_TRACKING_STATUS.ST_SHORTLISTED,
  [Status.ST_APPLD]: OLD_API_APPLICATION_TRACKING_STATUS.ST_APPLIED,
  [Status.ST_CONN]: OLD_API_APPLICATION_TRACKING_STATUS.ST_CONTACT_IN_PROGRESS,
  [Status.ST_DRFT]: OLD_API_APPLICATION_TRACKING_STATUS.ST_DRAFTED,
};

export const ENUM_COMPANY_STATUS_NUMBER_TO_STRING_MAP: { [key: number]: string } = {
  [ENUM_COMPANY_STATUS.ACTIVE]: 'ACTIVE',
  [ENUM_COMPANY_STATUS.CREATED]: 'UNDER_REVIEW',
  [ENUM_COMPANY_STATUS.GHOSTED]: 'GHOSTED',
};
export enum method {
  INVITE_METHOD_MOB_APP = 'mobile_app',
}

export enum emailVerified {
  YES = 'YES',
  NO = 'NO',
}

export enum OrderFulFillmentStatusEnum {
  INITIATED = 'INITIATED',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}
export enum PaymentActionUserTypeEnum {
  EMPLOYER = 'EMPLOYER',
}
export enum PaymentStatusEnum {
  INITIATION_FAILED = 'INITIATION_FAILED',
  INITIATED = 'INITIATED',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
}

export enum recommendationSortingEnum {
  relevance = 'wt_score',
  distance = 'distance',
  last_active = 'last_activity',
}

export enum BilldeskPaymentStatusEnum {
  SUCCESS = '0300',
  FAILED = '0399',
  PENDING = '0002',
  NOT_RECEIVED = 'NOT RECEIVED',
}

export enum PaymentActivityTypeEnum {
  INITIATE_PAYMENT = 'INITIATE_PAYMENT',
  PROCESS_PAYMENT_REQUEST = 'PROCESS_PAYMENT_REQUEST',
  PROCESS_PAYMENT_REQUEST_FAILED = 'PROCESS_PAYMENT_REQUEST_FAILED',
  PROCESS_PAYMENT_RESPONSE_ENCODED = 'PROCESS_PAYMENT_RESPONSE_ENCODED',
  PROCESS_PAYMENT_RESPONSE_DECODED = 'PROCESS_PAYMENT_RESPONSE_DECODED',
  WEBHOOK_RESPONSE_ENCODED = 'WEBHOOK_RESPONSE_ENCODED',
  WEBHOOK_RESPONSE_DECODED = 'WEBHOOK_RESPONSE_DECODED',
  FETCH_STATUS_PAYMENT_REQUEST = 'FETCH_STATUS_PAYMENT_REQUEST',
  FETCH_STATUS_PAYMENT_REQUEST_FAILED = 'FETCH_STATUS_PAYMENT_REQUEST_FAILED',
  FETCH_STATUS_PAYMENT_RESPONSE_ENCODED = 'FETCH_STATUS_PAYMENT_RESPONSE_ENCODED',
  FETCH_STATUS_PAYMENT_RESPONSE_DECODED = 'FETCH_STATUS_PAYMENT_RESPONSE_DECODED',
}
export enum PaymentActivityActionEnum {
  GATEWAY_REQUEST_GENERATED = 'GATEWAY_REQUEST_GENERATED',
  GATEWAY_RETURN_URL_RESPONSE_RECEIVED = 'GATEWAY_RETURN_URL_RESPONSE_RECEIVED',
  GATEWAY_RESPONSE_RECEIVED = 'GATEWAY_RESPONSE_RECEIVED',
  INITIATION_SUCCESSFUL = 'INITIATION_SUCCESSFUL',
}

export enum PaymentActivitySubActionEnum {
  RETURN_URL_RESPONSE_ENCODED = 'RETURN_URL_RESPONSE_ENCODED',
  FETCHING_STATUS = 'FETCHING_STATUS',
  PAYMENT_INITIATION = 'PAYMENT_INITIATION',
  PAYMENT_INITIATION_FAILED = 'PAYMENT_INITIATION_FAILED',
}

export enum PaymentModeEnum {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export enum PaymentMethodEnum {
  CASH = 'cash',
  ONLINE = 'online',
}

export enum PaymentGatewayEnum {
  BILLDESK = 'billdesk',
  CCAVENUE = 'ccavenue',
  CASH = 'cash',
}

export enum PaymentGatewayStatusEnum {
  PROCESSED = 'processed',
  SUCCESS = 'success',
  INPROGRESS = 'in_progress',
  FAILED = 'failed',
}

//LOGGER context

export enum LOGGER_CTX {
  LOGIN_AND_REGISTER = 'AUTHENTICATION : LOGIN_AND_REGISTER',
  LOGIN = 'AUTHENTICATION : LOGIN',
  REGISTER = 'AUTHENTICATION : REGISTER',
  OTP = 'AUTHENTICATION : OTP',
  KYC_STATE_MACHINE = 'KYC_STATE_MACHINE',
  KYC_EVENT_MANAGER = 'KYC_EVENT_MANAGER',
  NOTIFICATION = 'NOTIFICATION',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
  INAPP_NOTIFICATION = 'INAPP_NOTIFICATION',
  ORDER_FULFILLMENT = 'ORDER_FULFILLMENT',
  HIRING_SUBSCRIPTION = 'HIRING_SUBSCRIPTION',
  LOCATION_SERVICE = 'LOCATION_SERVICE',
  COMPANY_PROFILE = 'COMPANY_PROFILE',
  TALENT_PROFILE = 'TALENT_PROFILE',
  CHAT_MESSAGE = 'CHAT_MESSAGE',
  CONTEXT_NUDGE = 'CONTEXT_NUDGE',
  WATI = 'WATI',
  APPLICATION_TRACKING = 'APPLICATION_TRACKING',
  JOB_SCREENING = 'JOB_SCREENING',
  APP_RELEASE = 'APP_RELEASE',
  SEARCH = 'SEARCH',
  APP_UPDATE = 'APP_UPDATE',
  PARTNER_JOB_POSTING = 'PARTNER_JOB_POSTING',
  PARTNER_USER_REGISTRATION = 'PARTNER_USER_REGISTRATION',
  YOUTH_HUB_JOB_POSTING = 'YOUTH_HUB_JOB_POSTING',
  FILTER = 'FILTER',
  ELASTIC_SEARCH_DATA_INSERTION = 'ELASTIC_SEARCH_DATA_INSERTION',
  ELASTIC_SEARCH = 'ELASTIC_SEARCH',
  ELASTIC_SEARCH_DATA_UPDATE = 'ELASTIC_SEARCH_DATA_UPDATE',
  JOB_SEARCH_CONTEXT_FETCHING = 'JOB_SEARCH_CONTEXT_FETCHING',
  DESCRIPTION_GENERATION = 'DESCRIPTION_GENERATION',
}
export const NullGeolocation: Geolocation = {
  latitude: null,
  longitude: null,
  isNull: true,
};

export const NullDistance = null;

export enum HIRING_USER_ACTIVITY_TYPE {
  UNKNOWN = 'UNKNOWN',
  APP_TRACKING_APPLY = 'APP_TRACKING_APPLY',
  APP_TRACKING_SHORTLIST = 'APP_TRACKING_SHORTLIST',
  APP_TRACKING_INVITE = 'APP_TRACKING_INVITE',
  APP_TRACKING_IGNORE_INVITATION = 'APP_TRACKING_IGNORE_INVITATION',
  APP_TRACKING_ACCEPT_INVITATION = 'APP_TRACKING_ACCEPT_INVITATION',
  APP_TRACKING_TEXT_MESSAGE = 'APP_TRACKING_TEXT_MESSAGE',
  APP_TRACKING_PUBLISH_JOB = 'APP_TRACKING_PUBLISH_JOB',
  APP_TRACKING_VOICE_CALL_NON_APP_NUMBER_UNLOCKED = 'APP_TRACKING_VOICE_CALL_NON_APP_NUMBER_UNLOCKED',
  EMAIL_GENERATING = 'email_otp_generating',
  EMAIL_VERIFYING = 'email_otp_verifing',
  EMAIL_EXPIRING = 'email_otp_expiring',
  EMAIL_VERIFICATION_FAILED = 'email_otp_verification_failed',
  MOB_GENERATING = 'mob_otp_generating',
  MOB_VERIFYING = 'mob_otp_verifing',
  MOB_EXPIRING = 'mob_otp_expiring',
  MOB_VERIFICATION_FAILED = 'mob_otp_verification_failed',
  EMAIL_LINK_SENT = 'email_link_sent',
  EMAIL_LINK_VERIFYING = 'email_link_verification',
  UPDATE_EMAIL_BY_ADMIN = 'update_email_by_admin',
  EMAIL_PIN_VERIFICATION_SUCCESS = 'email_pin_verification_success',
  MOB_OTP_SEND_SUCCESS = 'mob_otp_send_success',
  EMAIL_PIN_VERIFICATION_FAILED = 'email_pin_verification_failed',
  MOB_OTP_RESEND_FAILED = 'mob_otp_resend_failed',
  MOB_OTP_RESEND_SUCCESS = 'mob_otp_resend_success',
  EMP_SHOW_RECRUITER_APP_NUDGE = 'emp_show_recruiter_app_nudge',
  EMAIL_OTP = 'email_otp',
  EMAIL_UPDATED = 'email_updated',
  EMAIL_VERIFICATION_LINK = 'email_verification_link',
  EMAIL_VERIFICATION = 'email_verification',
  MOB_OTP_SEND_FAILED = 'mob_otp_send_failed',
  INVITED_TALENT = 'invited_talent',
  REJECT_APPLICATION = 'reject_application',
  INTERVIEW_APPLICANT = 'interview_applicant',
  HIRE_APPLICANT = 'hire_applicant',
}

export enum JobRolePriority {
  YES = 'YES',
  NO = 'NO',
}
export enum EnumWhatsAppProvider {
  WATI = 'WATI',
}

export enum ResponseStatusText {
  SUCCESS = 'success',
  FAIL = 'fail',
}

export enum EnumKycActions { //TODO: Need to check Name it so similar to other list
  KYC_ACTIVITY_ACTION_SUBMIT = 'submit',
  KYC_ACTIVITY_ACTION_VERIFY = 'verify',
  KYC_ACTIVITY_ACTION_REJECT = 'reject',
  KYC_ACTIVITY_ACTION_ASK_FOR_UPDATE = 'ask_for_update',
  KYC_ACTIVITY_ACTION_DIRECT_REJECT = 'direct_reject',
  KYC_ACTIVITY_ACTION_EXPIRE = 'expire',
  KYC_ACTIVITY_ACTION_MARK_INACTIVE = 'mark_inactive',
}

export enum EnumKycAction { //TODO: Need to check Name it so similar to other list
  KYC_INITIATION = 'KYC_INITIATION',
  PROOF_SUBMISSION = 'PROOF_SUBMISSION',
  ATTEMPT_INITIATION = 'ATTEMPT_INITIATION',
  ATTEMPT_FINISING = 'ATTEMPT_FINISING',
  PROOF_REJECTING = 'PROOF_REJECTING',
  PROOF_ACCEPTING = 'PROOF_ACCEPTING',
  PROOF_UPDATION = 'PROOF_UPDATION',
  ACCEPTING_KYC = 'ACCEPTING_KYC',
  REJECTING_KYC = 'REJECTING_KYC',
  KYC_VERIFICATION = 'KYC_VERIFICATION',
}

export enum FILES_CONTEXT {
  EXCEL = 'EXCEL',
  DOCUMENTS = 'DOCUMENTS',
}

export enum ExcelTemplateString {
  DATA_COLLECTION_ID = 'dataCollectionId (Do not change)',
  ORDER_DETAILS = 'Order Details',
}

export enum BulkUploadLinkSend {
  SENT = 'SENT',
  NOT_SENT = 'NOT_SENT',
}

export enum CDC_SOURCE {
  OPS = 'OPS TEAM',
  CDC_SURVEY = 'DATA COLLECTION SURVEY',
  EMP_BULK_UPLOAD = 'EMPLOYER BULK UPLOAD',
  NOT_FOUND = 'NO DATA COLLECTED',
}

export enum FieldStatus {
  VALID = 'VALID',
  EMPTY = 'EMPTY',
  INVALID = 'INVALID',
}

export enum ExculdedFieldDataType {
  FILE = 'file',
  ADDRESS_CHECKBOX = 'address_checkbox',
  EMPLOYMENT_CHECKBOX = 'employment_checkbox',
  DOB_DATE_MONTH_YEAR = 'dob_date_month_year',
  CALENDAR_MONTH_YEAR = 'calender_month_year',
  SINGLE_SELECT = 'single_select',
}

export enum FilterStatus {
  INITIATED_THIS_MONTH = 'INITIATED_THIS_MONTH',
  INITITATED_LAST_30_DAYS = 'INITITATED_LAST_30_DAYS',
  INITITATED_LAST_90_DAYS = 'INITITATED_LAST_90_DAYS,',
  RANGE_DATE = 'RANGE_DATE',
  CUSTOM = 'CUSTOM',
  ALL = 'ALL',
  CLEAR = 'CLEAR',
  DISCREPANT = 'DISCREPANT',
  NO_RESPONSE = 'NO_RESPONSE',
  INSUFFICIENT = 'INSUFFICIENT',
  INPROGRESS = 'INPROGRESS',
  BLOCKED = 'BLOCKED',
  INITIATED = 'INITIATED',
  DATACOLLETED = 'DATACOLLETED',
  CHECK_IN_PROGRESS = 'CHECK_IN_PROGRESS',
  QUALITY_CHECK = 'QUALITY_CHECK',
  COMPLETED = 'COMPLETED',
  CANCELATION_REQUESTED = 'CANCELLATION REQUESTED',
  CANCELLED = 'CANCELLED',
  LAST_30_DAYS = '30',
  UNABLE_TO_VERIFY = 'UNABLE_TO_VERIFY',
  NOT_REQUIRED = 'NOT_REQUIRED',

  ALL_ORDERS = 'All Orders',
  INPROGRESS_STATUS = 'Inprogress',
  CLEAR_STATUS = 'Clear',
  DISCREPANT_STATUS = 'Discrepant',
  INSUFFICIENT_STATUS = 'Insufficient',
  NO_RESPONSE_STATUS = 'No Response',
  UNABLE_TO_VERIFY_STATUS = 'Unable to Verify',
  NOT_REQUIRED_STATUS = 'Not Required',
}

export enum ReportStatusUIText {
  YET_TO_GENERATE = 'Yet to Generate',
  INSUFFICIENT = 'Insufficient',
  CANCELATION_REQUESTED = 'Cancelled',
  CANCELLED = 'CANCELLED',
}

export enum SortingOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum OrderBy {
  CREATED = 'createdAt',
  UPDATED = 'updatedAt',
}

export enum SelfDeclaration {
  PENDING = 'pending',
  MANUAL = 'manual',
  ESIGN = 'esign',
  COMPLETED = 'completed',
}

export enum DATA_STATUS {
  PENDING = 'pending',
  COMPLETED = 'completed',
  INPROGRESS = 'inprogress',
  NOT_REQUIRED = 'not_required',
}

// Process Enums
export enum ProcessType {
  BULK_CONSENT_DOWNLOAD = 'BULK_CONSENT_DOWNLOAD',
  BULK_REPORT_DOWNLOAD = 'BULK_REPORT_DOWNLOAD',
}

export enum BulkDownloadType {
  REPORT = 'report',
  CONSENT = 'consent',
}

export enum DataCollectionStatus {
  COMPLETED = 'Completed',
  INITIATED = 'Initiated', //TODO: Created Initiated as Default value for Making this DataCollection To work
  PENDING = 'Pending',
}

export enum LanguageProficiency {
  Beginner = 0,
  Intermediate = 1,
  Advanced = 2,
  Expert = 3,
}

export enum EnumWorkShift {
  MORNING_SHIFT = 'morning_shift',
  NIGHT_SHIFT = 'night_shift',
}
export enum TalentAvailability {
  AVAILABLE = 'Available',
}

export enum filterContext {
  DASHBOARD = 'dashboard',
  RECOMMENDATION = 'recommendation',
}
export enum RecommendationFilters {
  WORK_TYPE = 'workingType',
  CITY = 'city',
  DISTANCE = 'distance',
  WORK_LOCATION = 'workLocation',
}
export enum DashBoardFilters {}
export const filterParamsValue: Record<filterContext, Record<string, any>> = {
  [filterContext.DASHBOARD]: {},
  [filterContext.RECOMMENDATION]: {
    [RecommendationFilters.WORK_TYPE]: Object.values(jobWorkingType),
    [RecommendationFilters.WORK_LOCATION]: Object.values(JobPostingWorkingLocation),
    [RecommendationFilters.DISTANCE]: [10, 20, 50],
    [RecommendationFilters.CITY]: [],
  },
};

export enum DatabaseTableIdPrefix {
  CompanyProfile = 'CP-',
  User = 'USER-',
  TalentProfile = 'TPR-',
  JobPosting = 'JP-',
  Suspicious = 'USRD-',
}

export enum GetAppliedListTypeData {
  CONTACTED = 'contacted',
  PUSHED = 'pushed',
}

export enum EnumNotificationChannel {
  Email = 'email',
  SMS = 'sms',
  Push = 'push',
  Wati = 'wati',
}
export enum Channel {
  MOBILE = 'MOBILE',
  UNKNOWN = 'UNKNOWN',
  WEB = 'WEB',
  PARTNER = 'PARTNER',
}

export enum ActionSourceFrom {
  ViewMobile = 'ViewMobile',
  ShortList = 'ShortList',
}

export enum EnumContextPolicy {
  JOB_DISCOVERY = 'JOB_DISCOVERY',
  CYCLIC_NUDGES = 'CYCLIC_NUDGES',
  COUNTS_POLICY = 'COUNTS_POLICY',
  NON_EXPIRY = 'NON_EXPIRY',
  NON_EXHAUSTING = 'NON_EXHAUSTING',
  EXHAUSTING = 'EXHAUSTING',
  CUSTOM = 'CUSTOM',
  TALENT_PROFILE_NOT_COMPLETED = 'TALENT_PROFILE_NOT_COMPLETED',
  SUCCESS_STORIES = 'SUCCESS_STORIES',
}

export enum EnumContextRule {
  TALENT_PROFILE_NOT_COMPLETED = 'TALENT_PROFILE_NOT_COMPLETED',
  JOB_DISCOVERY = 'JOB_DISCOVERY',
  ALWAYS_TRUE = 'ALWAYS_TRUE',
  ALWAYS_FALSE = 'ALWAYS_FALSE',
  UNATTENDED_INVITES = 'UNATTENDED_INVITES',
  UNREAD_MESSAGES = 'UNREAD_MESSAGES',
  MISSED_CALLS = 'MISSED_CALLS',
}

export enum EnumContextNudgeSource {
  DEFAULT = 'DEFAULT',
}

// enums.ts
export enum EnumContextAction {
  SCROLL_TO_SEGMENT = 'SCROLL_TO_SEGMENT',
  OPEN_SCREEN = 'OPEN_SCREEN',
}

export enum EnumContextTarget {
  RECOMMENDATIONS = 'recommendations',
  JOB_DETAILS = 'job_details',
  EDIT_PROFILE = 'edit_profile',
}

export enum EnumContextType {
  CHANGE_SECTION = 'CHANGE_SECTION',
  NAVIGATE = 'NAVIGATE',
}

// enums.ts
export enum EnumContextNudgeName {
  JOB_RECOMMENDATIONS = 'JOB_RECOMMENDATIONS',
  SIMILAR_JOBS = 'SIMILAR_JOBS',
  INCOMPLETE_PROFILE = 'INCOMPLETE_PROFILE',
  SUCCESS_STORIES = 'SUCCESS_STORIES',
  VERIFIED_JOBS = '100_VERIFIED_JOBS',
  QUICK_JOBS = 'QUICK_JOBS',
  HIRED_TODAY = 'HIRED_TODAY',
  PENDING_ACTIONS = 'PENDING_ACTIONS',
  MISSED_CALLS = 'MISSED_CALLS',
  UNREAD_MESSAGES = 'UNREAD_MESSAGES',
  UNATTENDED_INVITES = 'UNATTENDED_INVITES',
  MARKETING_BANNER = 'MARKATING_BANNER',
}
export enum EnumContextName {
  JOB_DISCOVERY = 'JOB_DISCOVERY',
  SUCCESS_STORIES = 'SUCCESS_STORIES',
  PLATFORM = 'PLATFORM',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  TALENT_PROFILE_COMPLETION = 'TALENT_PROFILE_COMPLETION',
}

export enum EnumContextLayoutType {
  UPDATE_PROFILE = 'updateProfile',
  THREE_CARD_LAYOUT = 'threeCardLayout',
  FOUR_CARD_LAYOUT = 'fourCardLayout',
}

export enum QuestionCategory {
  expType = 'expType',
  Education = 'education',
  Experience = 'experience',
  Skill = 'skill',
  Location = 'location',
  WorkType = 'worktype',
}
export enum EnumOnBoardingStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export enum QuestionType {
  TextInput = 'TextInput',
  SingleChoice = 'SingleChoice',
  LocationAutoComplete = 'LocationAutoComplete',
  MultiSelectBox = 'MultiSelectBox',
  SkillAutoComplete = 'SkillAutoComplete',
  CalenderYearMonth = 'CalenderYearMonth',
  CalenderYear = 'CalenderYear',
  EducationDropDown = 'EducationDropDown',
}
export enum AppName {
  HIRING_TLNT = 'HIRING_TLNT',
  HIRING_EMP = 'HIRING_EMP',
}
export enum AppPlatform {
  ANDROID = 'ANDROID',
  WEB = 'WEB',
}

export enum RedirectionAction {
  OPEN_SCREEN = 'OPEN_SCREEN',
  OPEN_MODAL = 'OPEN_MODAL',
  OPEN_GIGDETAILS = 'OPEN_GIGDETAILS',
  OPEN_DASHBOARD = 'OPEN_DASHBOARD',
  OPEN_SHORTLISTINGS_FOR_TALENT = 'OPEN_SHORTLISTINGS_FOR_TALENT',
  OPEN_PROFILE = 'OPEN_PROFILE',
  SCROLL_TO_SEGMENT = 'SCROLL_TO_SEGMENT',
  OPEN_LINK = 'OPEN_LINK',
}

export enum RedirectionLabel {
  VIEW_MESSAGE = 'View Message',
  VIEW_JOB = 'View Job',
  VIEW_NOW = 'View Now',
  EDIT_PROFILE = 'Edit Profile',
  UPDATE_NOW = 'Update Now',
  JOB_DETAILS = 'Job Details',
  BLANK = '',
}

export enum RedirectionSceenName {
  DASHBOARD = 'DashboardScreen',
  GIG_DETAIL = 'GigDetailScreen',
  MESSAGE_INTERACTION = 'MessageInteraction',
  CHAT_LIST = 'ChatListScreen',
  PROFILE = 'ProfileScreen',
  JOB_SEARCH = 'SearchScreen',
  SUCCESS_STORIES = 'successStoriesModal',
  VERIFIED_JOBS = 'verifiedJobsTrustModal',
  HIRED_TODAY = 'HiredTodayScreen',
  QUICK_TIPS = 'quickTipsModal',
}

export enum RedirectionSection {
  RECOMMENDATIONS = 'RECOMMENDATIONS',
}

export enum RedirectionContext {
  IN_APP_NOTIFICATION = 'IN_APP_NOTIFICATION',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
  CONTEXT_TILE = 'CONTEXT_TILE',
}

export enum InAppNotitificationType {
  ALL = 'all',
  UNREAD = 'unread',
}
export enum RedirectionType {
  CHANGE_SECTION = 'CHANGE_SECTION',
  NAVIGATE = 'NAVIGATE',
}
export enum NotificationRuleName {
  EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED',
  FIRST_JOB_NOT_POSTED = 'FIRST_JOB_NOT_POSTED',
  SUBSCRIPTION_NOT_BOUGHT = 'SUBSCRIPTION_NOT_BOUGHT',
  PROFILE_NOT_COMPLETED = 'PROFILE_NOT_COMPLETED',
  TALENT_MOB_OTP_NOT_VERIFIED = 'TALENT_MOB_OTP_NOT_VERIFIED',
  JOB_NOT_PUBLISHED = 'JOB_NOT_PUBLISHED',
  ALWAYS_TRUE = 'ALWAYS_TRUE',
}
export enum NotificationAction {
  SEND_MESSAGE = 'send_message',
  COMPLETE_NOTIFICATION_CYCLE = 'complete_notification_cycle',
}

export enum EnumNotificationEnv {
  DEV = 'DEV',
  PROD = 'PROD',
  TEST = 'TEST',
}

export enum EnumWatiTemplate {
  TalentMobileOtpUnverified = 'gigin_talent_mobile_otp_unverified_dyn_2',
  TalentPendingInvites = 'gigin_talent_pending_invites_dyn_2',
  TalentNotApplied = 'gigin_talent_not_applied_dyn_2',
  TalentShortlisted = 'gigin_talent_shortlisted_dyn_2',
  TalentUnreadMsgs = 'gigin_talent_unread_msgs_dyn_2',
  TalentMissedCall = 'gigin_talent_missed_call_dyn_2',
  TalentContactUnlocked = 'gigin_talent_contact_unlocked_dyn_2',
  EmpIncompleteProfile = 'gigin_emp_incomplete_profile_dyn_2',
  EmpEmailVerification = 'gigin_emp_email_verification_dyn_2',
  EmpIncompleteJobPost = 'gigin_emp_incomplete_job_post_dyn_2',
  EmpNewApplications = 'gign_emp_new_applications_dyn_2',
  EmpUnreadMsgs = 'gigin_emp_unread_msgs_dyn_2',
  EmpKycVerify = 'gigin_emp_kyc_verify_dyn_2',
  EmpKycReverify = 'gigin_emp_kyc_reverify_dyn_2',
  EmpPushedProfiles = 'gigin_emp_pushed_profiles_dyn_2',
  EmpSubscriptionPaymentFailed = 'gigin_emp_subscription_payment_failed_dyn_2',
  EmpLimitExhausted = 'gign_emp_limit_exhausted_dyn_2',
  EmpStartOnboarding = 'gigin_emp_start_onboarding_dyn_2',
  EmpMissedCall = 'gigin_emp_missed_call_dyn_2',
  EmpRecommendations = 'gigin_emp_recommendations_dyn_2',
  EmpInviteAccepted = 'gigin_emp_invite_accepted_dyn_2',
  YouthhubTalentApplication = 'gigin_talent_youthub_applied',
  Undefined = '',
}

export enum EnumWatiDynamicLink {
  SeekerLanding = 'Bh4mz/8w0m?_dl=seeker_landing&_p=seeker',
  SeekerLandingJobDetail = 'Bh4mz/b4gu?_dl=seeker_landing&_p=seeker&pssn=job_detail&pssid=',
  SeekersMessages = 'Bh4mz/0yh12?_dl=seekers_messages&_p=seeker',
  ProviderLanding = 'B2ob9/vb6w?_dl=provider_landing&_p=provider',
  ProviderMessages = 'B2ob9/83yn?_dl=provider_massages&_p=provider',
  VerifyCompanyStatus = 'B2ob9/5pec?_dl=verify_your_company_status&_p=provider',
  EmployerSubscriptionsTransactions = 'B2ob9/jyka8?_dl=employer_subscriptions_tranasctions&_p=provider',
  SeekerYouthhubApplicaiton = 'Dh4mz/on7p?_dl=seeker_landing&_p=seeker&pssn=job_detail&pssid=',
}

export enum EnumNotificationUser {
  HIRING = 'HIRING',
  BGV = 'BGV',
}

export enum EnumNotificationType {
  PUSH = 'PUSH',
  EMAIL = 'EMAIL',
  WHATSAPP = 'WHATSAPP',
  WHATSAPP_AND_PUSH = 'WHATSAPP_AND_PUSH',
}

export enum DisplayImageType {
  URL = 'url',
  ICON = 'icon',
  SVG = 'svg',
  IMAGE = 'image',
}

export enum DefaultImage {
  COMPANY_LOGO = '', //for now it's blank so that in frontend company name initials can be used
}

export enum PARTNER_JOB_POSTING_WORKFLOW_STATUS {
  DRAFT = 'DRAFT',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  READ_TO_REVIEW = 'READ_TO_REVIEW',
  TO_BE_POSTED = 'TO_BE_POSTED',
  PUBLISHED_SUCCESSFULLY = 'PUBLISHED',
  PUBLISHING_FAILED = 'PUBLISHING_FAILED',
  TO_BE_UNPUBLISHED = 'TO_BE_UNPUBLISHED',
  UNPUBLISHED_SUCCESSFULLY = 'UNPUBLISHED',
  UNPUBLISHING_FAILED = 'UNPUBLISHING_FAILED',
  EDITED = 'EDITED',
}

export enum PARTNER_JOB_POSTING_ACTION {
  CREATE = 'CREATE',
  REJECT = 'REJECT',
  ACCEPT = 'ACCEPT',
  MARK_READ_TO_REVIEW = 'MARK_READ_TO_REVIEW',
  MARK_TO_BE_POSTED = 'MARK_TO_BE_POSTED',
  PUBLISH = 'PUBLISH',
  UNPUBLISH = 'UNPUBLISH',
  MARK_TO_BE_UNPUBLISHED = 'MARK_TO_BE_UNPUBLISHED',
  UNPUBLISHING = 'UNPUBLISHING',
  PUBLISHING = 'PUBLISHING',
  PUBLISHING_SUCCESS = 'PUBLISHING_SUCCESS',
  PUBLISHING_FAILED = 'PUBLISHING_FAILED',
  UNPUBLISHING_SUCCESS = 'UNPUBLISHING_SUCCESS',
  UNPUBLISHING_FAILED = 'UNPUBLISHING_FAILED',
  UPDATING_APPLICATION_STATUS = 'UPDATING_APPLICATION_STATUS',
  APPLICATION_STATUS_UPDATE_SUCCESS = 'APPLICATION_STATUS_UPDATE_SUCCESS',
  APPLICATION_STATUS_UPDATE_FAILED = 'APPLICATION_STATUS_UPDATE_FAILED',
}

// Enum for action field
export enum PARTNER_ACTIVITY_ACTION {
  RECIEVE_TALENT_APPLICATION = 'RECIEVE_TALENT_APPLICATION',
}

export enum PARTNER_NAME {
  YOUTH_HUB = 'YOUTH_HUB',
}
export const PARTNER_JOB_PROVIDER_TOKEN = 'PARTNER_JOB_PROVIDER_TOKEN';
export enum JOB_SOURCE {
  GIGIN = 'GIGIN',
}

export enum PARTNER_APPLICATION_STATUS {
  TO_BE_APPLIED = 'TO_BE_APPLIED',
  TO_BE_INVITED = 'TO_BE_INVITED',
  INVITATION_TO_BE_ACCEPTED = 'INVITATION_TO_BE_ACCEPTED',
  INVITATION_TO_BE_IGNORED = 'INVITATION_TO_BE_IGNORED',
  INVITATION_TO_BE_REJECTED = 'INVITATION_TO_BE_REJECTED',
  TO_BE_SHORTLISTED = 'TO_BE_SHORTLISTED',
  UPDATE_SUCCESSFULLY = 'UPDATE_SUCCESSFULLY',
  UPDATION_FAILED = 'UPDATION_FAILED',
}

export enum PARTNER_APPLICATION_TRACKING_ACTION {
  APPLY = 'APPLY',
  INVITE = 'INVITE',
  ACCEPT_INVITATION = 'ACCEPT_INVITATION',
  IGNORE_INVITATION = 'IGNORE_INVITATION',
  REJECT_INVITATION = 'REJECT_INVITATION',
  SHORTLIST = 'SHORTLIST',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  MARKED_TO_BE_POSTED = 'MARKED_TO_BE_POSTED',
  INITIATED_POSTING_ON_PARTNER = 'INITIATED_POSTING_ON_PARTNER',
  RECEIVED_POSTING_ON_PARTNER_RESULT = 'RECEIVED_POSTING_ON_PARTNER_RESULT',
  MARKED_TO_BE_UNPUBLISHED = 'MARKED_TO_BE_UNPUBLISHED',
  INITIATED_UPDATING_STATUS_ON_PARTNER = 'INITIATED_UPDATING_STATUS_ON_PARTNER',
  RECEIVED_UPDATING_STATUS_ON_PARTNER = 'RECEIVED_UPDATING_STATUS_ON_PARTNER',
}

export enum YOUTH_HUB_APPLICATION_TRACKING_STATUS {
  APPLIED = 'Applied',
  VIEWED = 'Viewed',
  INTERVIEWED = 'Inverviewed',
  REJECTED = 'Rejected',
  HIRED = 'Hired',
}

export enum PARTNER_JOB_STATUS {
  YH_UNKNOWN = 'Unknwown',
  YH_ACTIVE = 'Active', //Youthhub
  YH_CLOSED = 'Closed', //Youthhub
}

export enum ApplicationActivityStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export const simplifiedLocationMapping: { [key in JobPostingWorkingLocation]: SimplifiedJobPostingWorkingLocation } = {
  [JobPostingWorkingLocation.WORK_FROM_HOME]: SimplifiedJobPostingWorkingLocation.WORK_FROM_HOME,
  [JobPostingWorkingLocation.OFFICE_WORKING]: SimplifiedJobPostingWorkingLocation.OFFICE_WORKING,
  [JobPostingWorkingLocation.FIELD_JOB]: SimplifiedJobPostingWorkingLocation.FIELD_JOB,
  [JobPostingWorkingLocation.HYBRID]: SimplifiedJobPostingWorkingLocation.HYBRID,
};

export const reverseLocationMapping: { [key in SimplifiedJobPostingWorkingLocation]: JobPostingWorkingLocation } = {
  [SimplifiedJobPostingWorkingLocation.WORK_FROM_HOME]: JobPostingWorkingLocation.WORK_FROM_HOME,
  [SimplifiedJobPostingWorkingLocation.OFFICE_WORKING]: JobPostingWorkingLocation.OFFICE_WORKING,
  [SimplifiedJobPostingWorkingLocation.FIELD_JOB]: JobPostingWorkingLocation.FIELD_JOB,
  [SimplifiedJobPostingWorkingLocation.HYBRID]: JobPostingWorkingLocation.HYBRID,
};
// Employer app static asset file names ENUMS
export enum EmployerAppAssetImageFileNames {
  AUTH_BACKGROUND_COVER = 'auth_background_cover.svg',
  AUTH_BANNER_IMAGE = 'auth_banner_image.png',
  VERIFYIN_BY_GIGIN_LIGHT = 'verifyin_by_gigin_light.svg',
  AUTH_LINK_SENT_SUCCESS = 'link_sent_success.svg',
  AUTH_LINK_SENT_ERROR = 'link_sent_error.svg',
  AUTH_NETWORK_ERROR = 'auth_network_error.svg',
  AUTH_SERVER_ERROR = 'auth_server_error.svg',
  AUTH_SETUP_MFA = 'secure_setup_mfa.svg',
  ORDER_LIST_NOT_CURRENT_ORG = 'order_list_no_org.svg',
}
export enum EmployerAppAssetIconsFileNames {
  ZIP_ICON_SVG = 'zip-icon.svg',
  EXCEL_ICON_SVG = 'excel-icon.svg',
  REPORT_DOCUMENTS_UPLOAD_ICON_SVG = 'report-documents-upload-icon.svg',
  FILES_ICON_SVG = 'files-icon.svg',
  UPLOAD_ICON_SVG = 'upload-icon.svg',
  UPLOAD_BULK_ICON_SVG = 'upload-bulk-icon.svg',
  JOBS_ICON_SELECTED_SVG = 'jobs-icon-selected.svg',
  XL_ICON_SVG = 'xl-icon.svg',
  YELLOW_INFO_ICON_SVG = 'yellow-info-icon.svg',
  IMG_ICON_SVG = 'img-icon.svg',
  TICK_WHITE_ICON_SVG = 'tick-white-icon.svg',
  INFO_BLUB_ICON_SVG = 'info-blub-icon.svg',
  BELL_ICON_SVG = 'bell_icon.svg',
  QUALITY_EMPTY_STATE_SVG = 'quality-empty-state.svg',
  MORE_DOTS_SVG = 'more_dots.svg',
  COPY_ICON_SVG = 'copy-icon.svg',
  INFO_BLUE_SVG = 'info_blue.svg',
  ALL_CHECK_STATUS_SVG = 'all_check_status.svg',
  INFO_ICON_SVG = 'info_icon.svg',
  JOBS_ICON_SVG = 'jobs-icon.svg',
  ERROR_VALUE_ICON_SVG = 'error-value-icon.svg',
  WARNING_ICON_SVG = 'warning_icon.svg',
  BLUE_ROUNDED_WARNING_SVG = 'blue-rounded-warning.svg',
  PDF_ICON_SVG = 'pdf-icon.svg',
  GIGIN_WHITE_SVG = 'gigin_white.svg',
  ACTION_WARNING_ICON_SVG = 'action-warning-icon.svg',
  CANDIDATE_ICON_SELECTED_SVG = 'candidate-icon-selected.svg',
  EDIT_ICON_SVG = 'edit-icon.svg',
  CALENDER_ICON_SVG = 'calender_icon.svg',
  MESSAGE_ICON_SELECTED_SVG = 'message-icon-selected.svg',
  REPORT_GENERATED_ROUND_SVG = 'report-generated-round.svg',
  TELEGRAM_SEND_ICON_SVG = 'telegram-send-icon.svg',
  WARNING_ROUNDED_SVG = 'warning-rounded.svg',
  TAT_ICON_SVG = 'tat-icon.svg',
  CANDIDATE_ICON_SVG = 'candidate-icon.svg',
  HOVER_EDIT_ROW_ICON_SVG = 'hover_edit_row_icon.svg',
  PENDING_ICON_SVG = 'pending_icon.svg',
  ACTION_MESSAGE_ICON_SVG = 'action-message-icon.svg',
  SUCCESS_CHECK_STATUS_SVG = 'success_check_status.svg',
  DATE_ICON_SVG = 'date-icon.svg',
  ROUND_WARNING_SVG = 'round-warning.svg',
  GIGIN_LOGO_COPY_SVG = 'gigin_logo copy.svg',
  FAILED_CHECK_STATUS_SVG = 'failed_check_status.svg',
  RND_CHART_SVG = 'rnd-chart.svg',
  CALL_ICON_SVG = 'call-icon.svg',
  GOTO_SVG = 'goto.svg',
  ANALYTICS_ICON_SELECTED_SVG = 'analytics-icon-selected.svg',
  REMARK_ICON_SVG = 'remark-icon.svg',
  EDIT_ROW_ICON_SVG = 'edit_row_icon.svg',
  ERROR_404_SVG = '404.svg',
  COMING_SOON_FEATURE = 'coming-soon-image.svg',
  RED_ICON_SVG = 'red-icon.svg',
  CUSTOM_FILE_UPLOAD_OUTLINE_SVG = 'custom_cloud_upload_outline.svg',
  VERIFYIN_GREEN = 'verifyin-green-icon.svg',
  VERIFYIN_RED = 'verifyin-red-icon.svg',
  VERIFYIN_GREY = 'verifyin-grey-icon.svg',
  VERIFYIN_AMBER = 'verifyin-amber-icon.svg',
  SEND_MAIL_SELECTED = 'send_mail_selected.svg',
  SEND_MAIL_UNSELECTED = 'send_mail_unselected.svg',
  UPLOAD_SELECTED = 'upload_selected.svg',
  UPLOAD_UNSELECTED = 'upload_unselected.svg',
  RETRY_PACKAGE = 'retry-icon.svg',
  PACKAGE_NOT_FOUND = 'notFound.svg',
  ORG_TREE_ICON = 'org_tree_icon.svg',
  AADHAAR_ICON = 'aadhaar.svg',
  PANCARD_ICON = 'pancard.svg',
  PASSPORT_ICON = 'passport.svg',
  CIRCLE_SUCCESS_FILLED = 'circle_success_filled_icon.svg',
  ARROW_RIGHT = 'arrow_right.svg',
  SUCCESS_ALL_COMPLETED = 'success_all_completed.svg',
}

export enum EnumBgvReportVerifyinStatus {
  VERIFYIN_GREEN = 'Verifyin Green',
  VERIFYIN_RED = 'Verifyin Red',
  VERIFYIN_GREY = 'Verifyin Grey',
  INPROGRESS = 'In-progress',
  VERIFYIN_AMBER = 'Verifyin Amber',
}

// Stytch Token Type ENUMS
export enum EnumStytchTokenType {
  LOGIN = 'login',
  RESET_PASSWORD = 'reset_password',
}

export enum EnumIndexedDbStore {
  FILES = 'files',
}

export enum EnumIndexedDbContextKey {
  BGV_CANDIDATE_LINK_CSV_UPLOAD = 'bgv_candidate_link_csv_upload',
  BGV_RECRUITER_BULK_CSV_UPLOAD = 'bgv_recruiter_bulk_csv_upload',
  BGV_RECRUITER_BULK_DOCUMENT_UPLOAD = 'bgv_recruiter_bulk_document_upload',
}

export enum DataCollectionItemSource {
  OPS_TEAM = 'OPS_TEAM',
  CANDIDATE_DATA_COLLECTION = 'CANDIDATE_DATA_COLLECTION',
  EMPLOYER_BULK_UPLOAD = 'EMPLOYER_BULK_UPLOAD',
}

export enum EnumsOrderCreationStepLabel {
  PACKAGES = 'Packages',
  CANDIDATE_DETAIL = 'Candidate Detail',
  UPLOAD_DOCUMENTS = 'Upload Documents',
}

export enum OrderCreationPackageContent {
  PACKAGE_API_FAIL_HEADER = 'We couldn’t load your packages',
  PACKAGE_API_FAIL_CONTENT = 'Looks like we had an issue with loading your packages. Please click refresh to try loading them again. If the issue persists, Please reach  out to our team at bgv-ops@gigin.ai or call us @+91 7259412221.',
  PACKAGE_NOT_FOUND_HEADER = 'Results not found',
  PACKAGE_NOT_FOUND_CONTENT = 'Select the package or search that most closely meets your needs. You can add more to the package once you select one',
  EMPTY_PACKAGE_ARRAY_HEADER = 'Your Packages are being created',
  EMPTY_PACKAGE_ARRAY_CONTENT = 'There aren’t any packages created for your account yet. Our team will be creating your packages soon. ',
  EMPTY_PACKAGE_ARRAY_SECONDARY_CONTENT = 'Please reach us at bgv-ops@gigin.ai or call us @+91 7259412221 for any queries. Report here',
}

export enum DocumentType {
  SUPPORTING_DOCUMENT = 'SUPPORTING_DOCUMENT',
  TEMPLATE_DOCUMENT = 'TEMPLATE_DOCUMENT',
  INSUFFICIENT_DATA_DOCUMENT = 'INSUFFICIENT_DATA_DOCUMENT',
}

export enum AuthOnboardLoginViewEnum {
  LOGIN = 'login',
  BASIC_DETAILS = 'basicDetails',
  SETUP_MFA = 'setupMFA',
  VALIDATE_MFA = 'validateMFA',
  BLOCKED = 'blocked',
}

// Enum for supported country codes
export enum CountryCodeEnums {
  INDIA = '+91',
  USA = '+1',
}

export enum CreatePasswordQueryParamEnums {
  CREATION_EPOCH = 'creation_epoch',
  FLOW = 'flow',
  TOKEN = 'token',
  EMAIL = 'email',
  INVITER_NAME = 'inviter_name',
  NAME = 'name',
  COMPANY_NAME = 'company_name',
  COMPANY_LOGO = 'company_logo',
}

export enum PasswordRequirementsEnum {
  PASSWORD_UPPERCASE = 'At least one upper case letter',
  PASSWORD_BREACH = 'No common words that can lead to password breach',
  PASSWORD_NUMBER = 'At least one numeric letter',
  PASSWORD_SPECIAL_CHARACTER = 'At least one special character',
  PASSWORD_MIN_LENGTH = 'Minimum length of 12 characters',
  PASSWORD_STRENGTH_LOW = 'Low',
  PASSWORD_STRENGTH_AVERAGE = 'Average',
  PASSWORD_STRENGTH_HIGH = 'Strong',
}
export enum AuthenticationFlows {
  ACCOUNT_ACTIVATION = 'account_activation',
  RESET_PASSWORD = 'reset_password',
  TEAM_INVITE = 'team_invite',
}

export enum AuthenticationTemplateID {
  SIGNUP = 'siginup_reset_password',
  RESET_PASSWORD = 'reset_password',
}

export enum AuthenticationExpirationConfigs {
  resetPasswordExpirationMinutes = 10080,
  totpExpirationMinutes = 30,
  stytchSessionDurationMinutes = 60 * 24,
}

export enum BlockedUserConfigs {
  MAX_FAIL_COUNT = 10,
}

export enum ProtectedFileViewSourceEnum {
  SESSION_STORAGE = 'sessionStorage',
  LOCAL_STORAGE = 'localStorage',
}

export enum BrowserStorageDataKeysEnum {
  AUTH_TOKEN = 'auth_token',
  USER_ACCOUNT_DETAIL = 'userAccountDetail',
  USER_DETAIL = 'userDetails',
  VERIFICATION_TOKEN = 'verificationToken',
  ROLE = 'role',
  COMPANY_SETTINGS_TAB = 'company_settings_tab',
  ORDER_ID = 'order_id',
  SELECTED_ACCOUNT = 'selectedAccount',
  USER_PERMISSIONS = 'userPermissions',
}

export enum EnumRouteSearchParam {
  REDIRECT_TO = 'redirectTo',
}

export enum EnumOrganisationLevel {
  PARENT_ORG = 'parent',
  SUBSIDIARY_ORG = 'subsidiary',
  GROUP_ORG = 'group',
}

export enum EnumPermissionsStoreKey {
  CAN_READ_ANALYTICS = 'canReadAnalytics',
  CAN_READ_ORDER = 'canReadOrder',
  CAN_READ_OPS = 'canReadOps',
  HAS_TOKEN = 'hasToken',
  CAN_CREATE_ORDER = 'canCreateOrder',
  CAN_UPDATE_ORDER = 'canUpdateOrder',
  CAN_DELETE_ORDER = 'canDeleteOrder',
  CAN_READ_COMPANY_PROFILE = 'canReadCompanyProfile',
  CAN_CREATE_COMPANY_PROFILE = 'canCreateCompanyProfile',
  CAN_UPDATE_COMPANY_PROFILE = 'canUpdateCompanyProfile',
  CAN_READ_TEAMS = 'canReadTeams',
  CAN_UPDATE_TEAMS = 'canUpdateTeams',
  CAN_CREATE_TEAMS = 'canCreateTeams',
  CAN_READ_USERS = 'canReadUsers',
  CAN_CREATE_USERS = 'canCreateUsers',
  CAN_UPDATE_USERS = 'canUpdateUsers',
  CAN_READ_PACKAGE = 'canReadPackage',
  CAN_CREATE_PACKAGE = 'canCreatePackage',
  CAN_UPDATE_PACKAGE = 'canUpdatePackage',
  CAN_READ_PREFERENCE = 'canReadPreference',
  CAN_CREATE_PREFERENCE = 'canCreatePreference',
  CAN_UPDATE_PREFERENCE = 'canUpdatePreference',
  CAN_READ_PREFERENCES = 'canReadPreferences',
  CAN_CREATE_PREFERENCES = 'canCreatePreferences',
  CAN_UPDATE_PREFERENCES = 'canUpdatePreferences',
  CAN_READ_AGREEMENT = 'canReadAgreement',
  CAN_READ_ROLES = 'canReadRoles',
  CAN_CREATE_ROLES = 'canCreateRoles',
  CAN_UPDATE_ROLES = 'canUpdateRoles',
  CAN_READ_REPORT = 'canReadReport',
  CAN_UPLOAD_TEMPLATE = 'canUploadTemplate',
  CAN_UPDATE_TEMPLATE = 'canUpdateTemplate',
  CAN_DELETE_TEMPLATE = 'canDeleteTemplate',
  CAN_READ_TEMPLATE = 'canReadTemplate',
  CAN_UPLOAD_DOCUMENT = 'canUploadDocument',
  CAN_UPDATE_DOCUMENT = 'canUpdateDocument',
  CAN_DELETE_DOCUMENT = 'canDeleteDocument',
  CAN_READ_DOCUMENT = 'canReadDocument',
  CAN_READ_GROUP_OF_COMPANIES = 'canReadGroupOfCompanies',
  CAN_READ_CANDIDATE = 'canReadCandidate',
  CAN_DELETE_CANDIDATE = 'canDeleteCandidate',
  CAN_UPDATE_CANDIDATE = 'canUpdateCandidate',
}

export enum AccountContext {
  TESTING = 'TESTING',
  INTERNAL = 'INTERNAL',
  CLIENT = 'CLIENT',
}

export enum DataCollectionAndDataCollectionItemStatus {
  CREATED = 'CREATED',
  INITIATED = 'INITIATED',
  RE_INITIATED = 'RE_INITIATED',
  COMPLETED = 'COMPLETED', // This is when either candidate, bulk upload or ops team has completed the data collection
  IN_PROGRESS = 'IN_PROGRESS',
  CONFIRMED = 'CONFIRMED', // This is after OPs approval after validation
  MARKED_NO_RESPONSE = 'MARKED_NO_RESPONSE',
  CANCELLED = 'CANCELLED',
}

export enum EnumAadhaarVerificationStepLabel {
  GENERATE_OTP = 'Generate OTP',
  FETCH_AADHAAR_DETAILS = 'Fetch Aadhaar Details',
}

export enum EnumStepperActionType {
  MARK_STEP_VISITED = 'MARK_STEP_VISITED',
  MARK_STEP_COMPLETED = 'MARK_STEP_COMPLETED',
  ADD_NEW_STEP = 'ADD_NEW_STEP',
  REMOVE_STEP = 'REMOVE_STEP',
}

export enum EnumAadhaarApiStatus {
  SUCCESS = 1,
  INVALID_OTP = 2,
  OTP_TIMED_OUT = 3,
  OTP_ALREADY_SUBMITTED = 4,
  AUTHKEY_INVALID = 401,
  PRIVILEGE_MISSING = 402,
  REQUEST_LIMIT_EXCEEDED = 403,
  PARAMETER_MISSING = 301,
  SOURCE_DOWN = 302,
}

export enum EnumAadhaarGenerateOTPStatus {
  SUCCESS = 1,
  INVALID_AADHAAR = 3,
  AADHAAR_NOT_LINKED = 4,
  AADHAAR_LOCKED = 5,
  OTHER = 7,
  AUTHKEY_INVALID = 401,
  PRIVILEGE_MISSING = 402,
  REQUEST_LIMIT_EXCEEDED = 403,
  PARAMETER_MISSING = 301,
  SOURCE_DOWN = 302,
}

export enum EnumPassPortApiStatus {
  SUCCESS = 1,
  NO_RECORD_FOUND = 2,
  INVALID_INPUT = 3,
  AUTHKEY_INVALID = 401,
  PRIVILEGE_MISSING = 402,
  REQUEST_LIMIT_EXCEEDED = 403,
  NO_WHITELISTED_ADDRESS = 404,
  PARAMETER_MISSING = 301,
  SOURCE_DOWN = 302,
}

export enum EnumPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum UrlPathName {
  VERIFICATION = 'verification',
  CASES = 'Cases',
  CHECKS = 'Checks',
  CLIENTS = 'Clients',
  BULK = 'Bulk',
  VERIFICATION_API = 'Verification API',
  AADHAR = 'Aadhar',
  PANCARD = 'Pancard',
  PASSPORT = 'Passport',
}

export enum DateFormatName {
  DATE_OF_BIRTH = 'Date of Birth',
  START_DATE = 'Start Date',
  END_DATE = 'End Date',
  DATE_OF_ISSUE = 'Date of Issue',
  VALIDITY_DATE = 'Validity Date',
  YEAR_OF_PASSING = 'Year of Passing',
  YEAR_OF_GRADUATION = 'Year of Graduation',
}

export enum UserAction {
  SAVE = 'save',
  CONFIRM = 'confirm',
  OTP = 'otp',
}
