// eslint-disable-next-line @nx/enforce-module-boundaries
import { proxy } from 'valtio';

interface SignedImage {
  value: string;
  createdAt: string;
  _id: string;
}

interface SelfDeclaration {
  self_declaration: boolean;
  data_collection_id: string;
  signature_type: string;
  signature_status: string;
  signed_image: SignedImage;
  signed_document: SignedImage;
  consent_template: string;
}

interface ISelfDeclarationType {
  self_declaration: SelfDeclaration;
  uploadTimeString: string;
}

export const SelfDeclarationStore: ISelfDeclarationType = proxy({
  self_declaration: {
    self_declaration: false,
    data_collection_id: '',
    signature_type: '',
    signature_status: '',
    signed_image: {
      value: '',
      createdAt: '',
      _id: '',
    },
    signed_document: {
      value: '',
      createdAt: '',
      _id: '',
    },
    consent_template: '',
  },
  uploadTimeString: '',
});

interface IOrderServiceRequestIdStore {
  orderServiceRequestId: string;
}
export const OrderServiceRequestIdStore: IOrderServiceRequestIdStore = proxy({
  orderServiceRequestId: '',
});

interface CollectionMetaData {
  is_otp_required: boolean;
}

interface DCCandidateData {
  collectionStatus: string;
  collectionMetaData: CollectionMetaData;
  otpVerified: boolean;
  collectionType: string;
  collectionGroupType: string;
  serviceRequestItemId: string;
}

interface CandidateDataStore {
  DCCandidateDataCollectionProgress: DCCandidateData[];
}

// Create the proxy store
export const CandidateDataStore: CandidateDataStore = proxy({
  DCCandidateDataCollectionProgress: [],
});
