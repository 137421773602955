export const baseUrl = process.env['NX_REACT_APP_API_URL'];

// API ENDPOINTS
export const endpoints = {
  GET_INITIAL_INFO: 'candidate-data-collection/information',
  GET_VERIFICATION_OTP: 'auth/candidate/send-otp',
  POST_VERIFICATION_OTP: 'auth/candidate/verify-otp',
  GET_DASHBOARD_MENU: 'candidate-data-collection/datacollection-list',
  GET_BODY_PARAMETER: 'candidate-data-collection/data-collection-structure',
  POST_FILE_S3: 'media-library/upload',
  PATCH_SAVE_AND_CONTINUE: 'candidate-data-collection/save',
  PATCH_CONFIRM_DETAILS: 'candidate-data-collection/confirmation',
  GET_AADHAAR_OTP: 'candidate-data-collection/aadhaar-verification',
  POST_AADHAAR_OTP_VERIFICATION: 'candidate-data-collection/verify-aadhar-otp',
  GET_SELF_DECLARATION_DETAILS: 'candidate-data-collection/fetch-data-collection',
  SELF_DECLARATION_SIGN_UPDATE: 'candidate-data-collection/update',
  GET_PARAMETER_DETAIL_VALUE_FOR_DATA_COLLECTION: 'candidate-data-collection/get-parameter-value',
  PATCH_CHECK_DATA_COLLECTION_STATUS: 'data-collection/update/check-data-collection-status',
};
