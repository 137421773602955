import { BKStyledButton } from '@gigin-work-space/common-ui';
import { ISection } from '@gigin-work-space/model';
import { CandidateDataStore, empCBUsageStore, OrderServiceRequestIdStore } from '@gigin-work-space/store';
import { AUTO_HIDE_TIMEOUT, COLLECTION_GROUP_TYPE, DATA_STATUS, STATUS, VARIANT_TYPE } from '@gigin-work-space/utils';
import { Box, Checkbox, FormControlLabel, Grid, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSnapshot } from 'valtio';
import { axiosInstance, endpoints } from '../../services';
import { FormFieldGenerator, MetaDetailsContainer } from '../common-ui';
import { CommonBottomDrawerHoc } from './common-bottom-drawer-hoc';

export interface EditableContainerHocProps {
  sectionList?: ISection[];
  relId?: string;
  fields: any[];
  note: any;
  relIdGroupTitle: string;
  navigateToStep: (param: string) => void;
  handleConfirm: () => void;
}

export const EditableContainerHoc = (props: EditableContainerHocProps) => {
  const { sectionList, relId, fields, note, navigateToStep, relIdGroupTitle, handleConfirm } = props;

  const [currentActiveStep, setCurrentActiveStep] = useState(0);
  const { cbValue, targetField } = useSnapshot(empCBUsageStore);
  const { orderServiceRequestId } = useSnapshot(OrderServiceRequestIdStore);

  const { DCCandidateDataCollectionProgress } = useSnapshot(CandidateDataStore);
  const [selectedServiceRequestItemId, setSelectedServiceRequestItemId] = useState<string | null>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // function to navigate to clicked section step if its completed
  const navigateToClickedStep = (data: any) => {
    navigateToStep(data.meta.goto.destination);
  };

  //   SIDE EFFECS
  useEffect(() => {
    const handleActiveStep = () => {
      if (sectionList) {
        const activeIndex = sectionList.findIndex((data) => data.rel_id === relId);
        if (activeIndex !== -1) {
          setCurrentActiveStep(activeIndex);
        } else {
          setCurrentActiveStep(0);
        }
      }
    };
    handleActiveStep();
  }, []);
  useEffect(() => {
    if (containerRef.current) {
      const stepWidth = 100;

      const scrollToPosition = currentActiveStep * stepWidth;

      const containerWidth = containerRef.current.offsetWidth;
      const excessWidth = Math.max(scrollToPosition - containerWidth / 2, 0);

      containerRef.current.scrollLeft = excessWidth;
    }
  }, [currentActiveStep]);

  const mapServiceRequestIds = (sectionList: any[], DCCandidateDataCollectionProgress: any[]) => {
    return sectionList.map((section) => {
      const match = DCCandidateDataCollectionProgress.find(
        (progress) =>
          progress.collectionType === section.rel_id &&
          progress.collectionGroupType === COLLECTION_GROUP_TYPE.employment_type,
      );

      return {
        ...section,
        serviceRequestItemId: match?.serviceRequestItemId || null,
      };
    });
  };

  const result = sectionList ? mapServiceRequestIds(sectionList, DCCandidateDataCollectionProgress) : [];

  const handleCheckboxClick = () => {
    setIsChecked(true);
    setOpenDialog(true);

    const matchedSection = result.find((section) => section.rel_id === relId);
    if (matchedSection) {
      const serviceRequestItemId = matchedSection.serviceRequestItemId;
      setSelectedServiceRequestItemId(serviceRequestItemId);
    }
  };

  const handleContinueClick = async () => {
    const idToUse = orderServiceRequestId;

    if (!idToUse || !selectedServiceRequestItemId) {
      enqueueSnackbar('Missing required data. Please try again.', {
        variant: VARIANT_TYPE.ERROR,
        autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
      });
      navigate('/dashboard');
      return;
    }

    try {
      setLoading(true);

      const payload = {
        status: STATUS.NOT_REQUIRED,
        isChecked: isChecked,
      };

      const response = await axiosInstance.patch(
        `${endpoints.PATCH_CHECK_DATA_COLLECTION_STATUS}/${idToUse}/${selectedServiceRequestItemId}`,
        payload,
      );

      if (!response) {
        throw new Error('Network response was not ok');
      }

      // On success
      setOpenDialog(false);
      handleConfirm();
    } catch (error) {
      enqueueSnackbar('Could not submit the data. Please try again later.', {
        variant: VARIANT_TYPE.ERROR,
        autoHideDuration: AUTO_HIDE_TIMEOUT.LONGER,
      });
      navigate('/dashboard');
    } finally {
      setLoading(false);
    }
  };

  const handleDrawerClose = () => {
    setOpenDialog(false);
    setIsChecked(false);
  };

  return (
    <>
      {/* Stepper Section */}

      {sectionList && sectionList.length > 1 && (
        <section className="px-6 pt-6 w-full">
          <Box className="bk-dc-stepper-section">
            <div ref={containerRef} className="bk-stepper-scroll">
              <Stepper activeStep={currentActiveStep} alternativeLabel>
                {sectionList.map((data, id) => (
                  <Step
                    key={data.rel_id}
                    completed={
                      data.status?.toLowerCase() === DATA_STATUS.COMPLETED ||
                      data.status?.toLowerCase() === DATA_STATUS.NOT_REQUIRED
                    }
                    disabled={data.status?.toLowerCase() === DATA_STATUS.PENDING}
                    onClick={() => navigateToClickedStep(data)}>
                    <StepLabel className="completed-step-icon">{data.title.en}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            <Box className="bk-stepper-side-blur" />
          </Box>
        </section>
      )}
      {/* Dynamic Form/Field Section */}
      <div className="w-full h-[2px]"></div>

      {COLLECTION_GROUP_TYPE.employment_detail === relIdGroupTitle && (
        // <>
        <FormControlLabel
          control={<Checkbox className="w-lg h-lg" checked={isChecked} onChange={handleCheckboxClick} />}
          label={<Typography className="text-bk_info bk-sub-heading1 ml-md">Not Required</Typography>}
          className="flex justify-start mt-lg ml-xl2"
        />
      )}

      {openDialog && (
        <CommonBottomDrawerHoc isOpen={openDialog} handleClose={handleDrawerClose}>
          <Stack spacing={2}>
            <Typography className="bk-headline3 text-bk_text_secondary">
              Are you sure you do not have the requested details?
            </Typography>
            <MetaDetailsContainer
              iconName="info_yellow_icon.svg"
              noteText={`You wouldn’t be able to fill these details at a later time`}
              fontStyle="bK-body1"
              bgColor="bk_tag_orange_bg"
              textColor="bk_text_secondary"
            />
            <Grid container alignItems={'center'}>
              <Grid item xs={6} paddingRight={'4px'}>
                <BKStyledButton
                  fullWidth
                  variant="outlined"
                  size="large"
                  className="!bK-body1"
                  onClick={handleDrawerClose}>
                  Go back
                </BKStyledButton>
                {/* the button i */}
              </Grid>
              <Grid item xs={6} paddingLeft={'4px'}>
                <BKStyledButton
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  className="!bK-body1"
                  onClick={handleContinueClick}>
                  {loading ? 'Submitting...' : 'Continue'}
                </BKStyledButton>
              </Grid>
            </Grid>
          </Stack>
        </CommonBottomDrawerHoc>
      )}

      <section className={sectionList && sectionList.length > 1 ? 'bk-main-stepper-section' : 'bk-main-section'}>
        <Grid container spacing={2} alignItems={'flex-start'} sx={{ pb: 2 }}>
          {fields &&
            fields.map((field) => (
              <Grid item xs={cbValue ? 12 : field.grid ? field.grid : 12} key={field.field_id}>
                <FormFieldGenerator params={field} />
              </Grid>
            ))}
          {note && (
            <Grid item xs={12}>
              <MetaDetailsContainer iconName="info_blue_icon.svg" noteText={note?.en} fontStyle="bk-sub-heading3" />
            </Grid>
          )}
        </Grid>
      </section>
    </>
  );
};
